import Login from '../views/Authentication/login.view.jsx';
import DadosCliente from '../views/Authentication/dadosCliente.view.jsx';

import Service from '../views/Services.view.jsx';
import EquipmentDetails from '../views/Details/equipmentDetails.view.jsx';
import ServiceDetails from '../views/Details/serviceDetails.view.jsx';
import FeedbackApp from '../views/Feedback/feedbackApp.jsx';
import SuporteCliente from '../views/Feedback/suporteCliente.jsx';
import SchedulePage from '../views/schedule/SchedulePage.jsx';
import HelpDeleteAccount from '../views/Authentication/HelpDeleteAccount.jsx';
import PaymentPage from '../views/payment/PaymentPage.jsx';


const authenticatedRoutes = [
  { path: `/schedule/:id?`, name: 'SchedulePage', component: SchedulePage },
  { path: `/services`, name: 'Services', component: Service },
  { path: `/equipment-details/:id`, name: 'EquipmentDetails', component: EquipmentDetails },
  { path: `/service-details/:id`, name: 'ServiceDetails', component: ServiceDetails },
  { path: `/perfil`, name: 'Perfil', component: DadosCliente },
  { path: `/enviar-feedback`, name: 'FeedbackApp', component: FeedbackApp },
  { path: `/suporte-cliente`, name: 'SuporteCliente', component: SuporteCliente },
  { path: `/help-delete-account`, name: 'HelpDeleteAccount', component: HelpDeleteAccount },
  { path: `/payment/:id`, name: 'PaymentPage', component: PaymentPage },
  {
    redirect: true, path: '/', to: `/services`, name: 'Services',
  },
];

const nonAuthenticatedRoutes = [
  { path: `/schedule-lite/:id?`, name: 'SchedulePage', component: SchedulePage },
  { path: `/services-lite`, name: 'Services', component: Service },
  { path: `/service-details-lite`, name: 'ServiceDetails', component: ServiceDetails },
  { path: '/login', name: 'Login', component: Login },
  { path: `/help-delete-account`, name: 'HelpDeleteAccount', component: HelpDeleteAccount },
  {
    redirect: true, path: '/', to: '/services-lite', name: 'Services',
  },
];

export {
  nonAuthenticatedRoutes,
  authenticatedRoutes,
};
