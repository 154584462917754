import React from 'react';
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router-dom';

import Header from 'components/header/header.jsx';
import Sidebar from 'components/sidebar/sidebar.jsx';

import { authenticatedRoutes } from 'routes/app.routes.js';
import PhoneCallButton from '../../components/button/PhoneCallButton';
import Emitter from '../../core/services';
import PlayStoreButton from '../../components/button/playstoreButton';
import Notifications from '../../components/notifications/notifications';

class Authenticated extends React.Component {
  componentWillUnmount() {
    Emitter.off('REGISTER_STATE_CHANGED');
  }

  render() {
    return (
      <div className="container-body" lang={"pt"}>
        <Sidebar />
        <Notifications />
        <div className="container-view-contex" id="container-view-contex">
          <Header />
          <Switch>
            {
              authenticatedRoutes.map((prop, key) => {
                if (prop.redirect) return (<Redirect from={prop.path} to={prop.to} key={key} />);
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    render={(props) => {
                      // Use a timestamp to force re-render
                      const uniqueKey = Date.now();
                      // Render the component with a unique key
                      return React.createElement(prop.component, {
                        ...props,
                        key: uniqueKey,
                      });
                    }}
                  />);
              })
            }
          </Switch>
        </div>
        <PhoneCallButton />
        <PlayStoreButton />
      </div>
    );
  }
}

export default withRouter(Authenticated);
