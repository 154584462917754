import React, { useEffect, useState, useRef } from "react";
import Helper from "../../../core/helper/helper";
import LocalData from "../../../core/localData";
import { addItemToUser, getItemList } from "../../../api/routes/api.route";
import Cookies from 'universal-cookie';
import { GENERAL } from "../../../assets/images/images";
import ScheduleAddEquipment from "./ScheduleAddEquipment";
import Labels from "../../../variables/labels";
/**
 * @param {Object} props
 * @param {boolean} props.stepActive
 * @param {string} props.itemInfoTitle
 * @param {string} props.itemInfoSubtitle
 * @param {Funtion} props.onStepActive
 * @param {string} props.itemInfoId
 * @returns
 */
const ScheduleTopInformation = (props) => {
  const cookies = new Cookies();
  const [stepActive, setStepActive] = useState(props.stepActive);
  //const [itemInfoSubtitle, setitemInfoSubtitle] = useState(props.itemInfoSubtitle);
  //const [itemInfoTitle, setitemInfoTitle] = useState(props.itemInfoTitle);
  //const [itemInfoId, setitemInfoId] = useState(props.itemInfoId);
  //const [randomTitle, setRandomTitle] = useState(Helper.newRandomString(Helper.printValue(props.itemInfoTitle)?.length - Helper.printValue(props.itemInfoTitle)?.slice(" ")[1]?.length));
  //const [randomSubtitle, setRandomSubtitle] = useState(Helper.newRandomString(Helper.printValue(props.itemInfoSubtitle)?.length - Helper.printValue(props.itemInfoSubtitle)?.slice(" ")[1]?.length));
  const [hasServiceFixedDate, setHasServiceFixedDate] = useState(props.hasServiceFixedDate);
  const [equipmentsData, setEquipmentsData] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [showEquipmentList, setShowEquipmentList] = useState(true);
  const [addEquipment, setAddEquipment] = useState(false);
  //const [loadingStateAddItem, setLoadingStateAddItem] = useState(false);
  const [addItemFields, setAddItemFields] = useState(props.tempEquipData || Helper.itemFieldsToAdd());
  const [disabledAddItemButton, setDisabledAddItemButton] = useState(!Helper.hasValidFields(addItemFields));

  //console.log("props.tempEquipData", props.tempEquipData);
  //console.log("addItemFields", addItemFields);
  const scheduleAddEquipmentRef = useRef(null);

  useEffect(() => {
    if (!showEquipmentList) {
      //console.log("Resetting add item fields");
      resetAddItemFields();
    }
  }, [showEquipmentList]);

  useEffect(() => {
    setHasServiceFixedDate(props.hasServiceFixedDate);
  }, [props.hasServiceFixedDate]);

  useEffect(() => {
    setStepActive(props.stepActive);
  }, [props.stepActive]);

  // useEffect(() => {
  //   //setitemInfoSubtitle(props.itemInfoSubtitle);
  //   setRandomSubtitle(Helper.newRandomString(Helper.printValue(props.itemInfoSubtitle)?.length - Helper.printValue(props.itemInfoSubtitle)?.slice(" ")[1]?.length));
  // }, [props.itemInfoSubtitle]);

  // useEffect(() => {
  //   //setitemInfoTitle(props.itemInfoTitle);
  //   setRandomTitle(Helper.newRandomString(Helper.printValue(props.itemInfoTitle)?.length - Helper.printValue(props.itemInfoTitle)?.slice(" ")[1]?.length));
  // }, [props.itemInfoTitle]);

  // useEffect(() => {
  //   setitemInfoId(props.itemInfoId);
  // }, [props.itemInfoId]);

  useEffect(() => {
    callGetItemsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetAddItemFields = () => {
    //setAddItemFields(Helper.itemFieldsToAdd());
    if (scheduleAddEquipmentRef.current) {
      //console.log("Found. Resetting add item fields");
      scheduleAddEquipmentRef.current.resetState();
    }
    setAddEquipment(false);
  };

  const addItem = async (event) => {
    const fields = event;
    const newItem = Helper.buildEquipmentSpecsBody(fields);
    if (Helper.isLiteVersion()) {
      //const query = this.props.location.search;
      //const token = new URLSearchParams(query).get("token");
      //const tokenCal = new URLSearchParams(query).get("token_cal");
      // //console.log("LocalData.equipmentsData", LocalData.equipmentsData);

      // Helper.buildLiteEquipmentData(fields, null, null);
      
      // //console.log("LocalData.equipmentsData", LocalData.equipmentsData);
      // LocalData.liteItem = true;
      //this.getItemListData();
    } else {
      // setLoadingStateAddItem(true);
      //console.log("Adding item to user", newItem);
      addItemToUser(cookies.get("sessionToken"), newItem).then((value) => {
        // setLoadingStateAddItem(false);
        if (value) {
          if (value.code) {
            if (value.code === 401) {
              Helper.callLogError("401 addItemToUser " + cookies.get("sessionToken") + " ");
              return;
            }
            if (value.code === 409) {
              //console.log("ERROR", value.message);
              // Modal.create(
              //   <Information
              //     title={Labels.registedErrorTitle}
              //     text={value.message}
              //     onClick={() => Modal.close()}
              //   />
              // );
            } else if (value.code === 429) {
              props.renderInformationModal(Labels.registedErrorTitle, value.message);
            } else {
              //console.log("ERROR", value.message);
              // Modal.create(
              //   <Information
              //     title={Labels.registedErrorTitle}
              //     text={Labels.genericErrorMessage}
              //     onClick={() => Modal.close()}
              //   />
              // );
            }
          } else {
            //console.log("OK !", value.message);
            // Modal.create(
            //   <Information
            //     title={Labels.registedSuccessTitle}
            //     text={value.message}
            //     onClick={() => Modal.close()}
            //   />
            // );
            callGetItemsApi();
          }
        } else {
          //console.log("ERROR", value.message);
          // Modal.create(
          //   <Information
          //     title={Labels.registedErrorTitle}
          //     text={Labels.genericErrorMessage}
          //     onClick={() => Modal.close()}
          //   />
          // );
        }
        setShowEquipmentList(false);
      });
    }
  };

  const callGetItemsApi = async () => {
    if (LocalData.terms_accepted) {
      //console.log("Calling getItemList");
      try {
        const value = await getItemList(cookies.get("sessionToken"));
        if (value && !value.code && value.items && value.items?.length > 0) {
          LocalData.equipmentsData = value.items;
          setEquipmentsData(value.items);
          setSelectedEquipment(value.items[0]); // Seleciona o primeiro equipamento por padrão
          setShowEquipmentList(false);
          resetAddItemFields();
          if (props.handleItemSelected) props.handleItemSelected(value.items[0].id);
        } else {
          //console.log("ERROR or no items", value);
        }
      } catch (error) {
        console.error("Failed to fetch item list", error);
      }
    }
  };

  const handleSelectEquipment = (equipment) => {
    setSelectedEquipment(equipment);
    setShowEquipmentList(false);
    setAddEquipment(false);
    resetAddItemFields();
    if (props.handleItemSelected) props.handleItemSelected(equipment.id);
  };

  const handleAddEquipment = (inList) => {
    setAddEquipment(inList);
    //TODO: scroll id={equipListContainer} to bottom
    setTimeout(() => {
      const container = document.getElementById("equipListContainer");
      if (container) {
        container.scrollTo({ top: container.scrollHeight, behavior: "smooth" });
      }
    }, 300);
  };

  const renderEquipmentSelection = () => {
    if (equipmentsData.length === 0) return null;

    return (
      <>
        <div id="equipListContainer" className={`schedule-service-top-info-list-container ${showEquipmentList ? 'open' : ''}`}>
          {equipmentsData.length > 1 && equipmentsData.map((equipment) => (
            <div
              key={equipment.id}
              className={`schedule-service-top-info-container list ${selectedEquipment?.id === equipment.id ? 'selected' : ''}`}
              onClick={() => handleSelectEquipment(equipment)}
            >
              <div className='schedule-service-top-image-container'>
                <img
                  src={Helper.urlItemImage(equipment.image)}
                  alt="serviceImage"
                  className="schedule-service-top-image"
                  onError={(e) => { e.target.src = Helper.defaultImage(); e.target.onerror = null; }}
                />
              </div>
              <div className="schedule-service-top-info">
                <div className='schedule-service-top-title'>{equipment.title}</div>
                <div className='schedule-service-top-subtitle'>{equipment.subtitle}</div>
              </div>
            </div>
          ))}
          {!addEquipment && (
            <div
              className="cta-button-secondary m-2 w-100 d-flex justify-content-center align-items-center"
              style={{ maxWidth: "98%" }}
              role="button"
              onClick={() => handleAddEquipment(true)}
            >
              {`+ Adicionar ${Helper.itemPronoun4()} ${Helper.itemLabel()}`}
            </div>
          )}
          {addEquipment && (
            <>
              {renderAddEquipment(true)}
              <div
                className="cta-button-secondary m-2 w-100 d-flex justify-content-center align-items-center"
                style={{
                  maxWidth: "98%",
                  cursor: disabledAddItemButton ? "default" : "pointer",
                  opacity: disabledAddItemButton ? 0.6 : 1,
                }}
                role="button"
                onClick={() => {
                  if (!disabledAddItemButton) {
                    //console.log("Guardar novo equipamento");
                    addItem(addItemFields);
                  }
                }}
              >
                {`Guardar nov${Helper.itemPronoun1().toLowerCase()} ${Helper.itemLabel().toLowerCase()}`}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const renderStepsContainer = () => {
    if (props.isLockedToStep3 || props.isLockedToStep2) return null;


    const steps = [1, 2, 3];

    const handleStepClick = (step) => {
      // Disable clicking on step 2 when hasServiceFixedDate (fixed date does not allow changing the date in step 2)
      if (hasServiceFixedDate && stepActive === 3 && step === 2) return;

      setStepActive(step);
      props.onStepActive(step);
    };

    return (
      <div className="steps-container mt-4">
        {steps.map((step, i) => (
          <div
            className={`step ${step === stepActive
                ? "active"
                : step > stepActive || (hasServiceFixedDate && stepActive === 3 && step === 2) 
                  || (props.isLockedToStep2 && step === 1) || (props.isLockedToStep3 && stepActive === 3 && step === 1)
                  ? "disabled"
                  : ""
              }`}
            role="button"
            key={i}
            onClick={() => {
              if (!(hasServiceFixedDate && stepActive === 3 && step === 2)
                && !(props.isLockedToStep2 && step === 1) && !(props.isLockedToStep3 && stepActive === 3 && step === 1)
              ) {
                handleStepClick(step);
              }
            }}
          >
            {step}
          </div>
        ))}
      </div>
    );
  };


  // const renderValue = (value, random) => {
  //   return (Helper.isLiteVersion() && !itemInfoId?.includes("int")) ? (
  //     <Fragment>
  //       <span>
  //         {(() => {
  //           const processedValue = Helper.printValue(value);
  //           if (processedValue.includes(" ") || processedValue.includes("-")) {
  //             return processedValue.split(" ")[0].split("-")[0];
  //           } else {
  //             return processedValue.slice(0, 2);
  //           }
  //         })()}
  //       </span>
  //       <span className='pixelized'>{random}</span>
  //     </Fragment>
  //   ) : (
  //     Helper.printValue(value)
  //   );
  // };

  const renderAddEquipment = (inList) => {
    const notLiteNoEquip = !Helper.isLiteVersion() && equipmentsData?.length === 0;
    return (
      <>
        <div className={`schedule-service-top-info-container add ${inList ? 'list' : ''} ${notLiteNoEquip ? 'notLiteNoEquip' : ''} `}>
          <div className="vehicle-title">
            {inList ?
              `Nov${Helper.itemPronoun1().toLowerCase()} ${Helper.itemLabel()}:` :
              `Introduza os dados ${Helper.itemPronoun5()} ${Helper.itemLabel().toLowerCase()} para Agendamento`
            }
          </div>

          <ScheduleAddEquipment
            ref={scheduleAddEquipmentRef}
            defaultItem={addItemFields}
            onChange={(data) => {
              //console.log("addEquip: ", data); /*this.handleEventAddItem(data)*/
              //console.log("Valid fields");
              //console.log(!Helper.hasValidFields(data));
              setDisabledAddItemButton(!Helper.hasValidFields(data));
              setAddItemFields(data);
              props.addTempEquipment(data);
              //console.log("Setting um temp equip data", data);

            }}
          ></ScheduleAddEquipment>
          {
            // <div className="equipment-inputs">
            //   <input
            //     type="text"
            //     className="shadow-box"
            //     placeholder="Matrícula"
            //   />
            //   <input
            //     type="text"
            //     className="shadow-box"
            //     placeholder="Modelo"
            //   />
            // </div>
          }
        </div>
        {(Helper.isLiteVersion() && (!equipmentsData || equipmentsData?.length === 0)) && (
          <div
            className="schedule-service-top-select-equipment"
            
          >
            <span>
              <span
                style={{ textDecoration: 'underline', color: 'inherit', cursor: 'pointer'}}
                onClick={() => props.toLogin(true)}
              >
                Fazer login
              </span>{' '}
              para ver {Helper.itemPronoun1().toLowerCase()}s {Helper.itemPronoun6().toLowerCase()}s {Helper.itemLabel().toLowerCase()}s
            </span>
          </div>
        )}
      </>
    );
  }

  const renderTopDescription = () => {
    // //console.log("Localdata", LocalData.equipmentsData);
    // //console.log("equipmentsData", equipmentsData);
    // //console.log("stepActive", stepActive);
    // //console.log("selectedEquipment", selectedEquipment);

    if (stepActive !== 3 && equipmentsData.length === 0) {
      return (
        <div className="schedule-service-description-container empty justify-content-center">
          <div className="service-description-text">Agendamento</div>
        </div>
      );
    }

    if (stepActive === 3 && equipmentsData.length === 0) {
      const inList = false
      return (
        <>
          {renderAddEquipment(inList)}
        </>
      );
    }

    return (
      <>
        <div className='schedule-service-top-info-container' >
          <div className='schedule-service-top-image-container'>

            <img
              src={Helper.urlItemImage(selectedEquipment?.image)}
              alt="serviceImage"
              className="schedule-service-top-image"
              onError={(e) => { e.target.src = Helper.defaultImage(); e.target.onerror = null; }}
            />
          </div>
          <div className="schedule-service-top-info">
            <div className='schedule-service-top-title'>
              {selectedEquipment?.title}
            </div>
            <div className='schedule-service-top-subtitle'>
              {selectedEquipment?.subtitle}
            </div>
          </div>

        </div>

        <div className={`schedule-service-top-select-equipment button ${showEquipmentList ? 'open' : ''}`}
          onClick={() => setShowEquipmentList(!showEquipmentList)}>
          <span>{equipmentsData.length === 1 ? "Adicionar " : "Selecionar "} {Helper.itemPronoun3()} {Helper.itemLabel().toLowerCase()}  </span>
          <img src={showEquipmentList ? GENERAL.iconSmallArrowUp : GENERAL.iconSmallArrowDown} alt="arrow-down" style={{ marginLeft: "8px" }} />
        </div>

        {renderEquipmentSelection()}


      </>
    );
  };

  return (
    <div className="top-information-container mt-5">
      {renderTopDescription()}
      {renderStepsContainer()}
    </div>
  );
};

export default ScheduleTopInformation;
