import React from 'react';
import { GENERAL } from '../../../assets/images/images';

function CardCorner (props) {
  const { data: {bars, feedback}, styleClass} = props;
  if (bars < 5 || !feedback || !feedback.classification) {
    return (<React.Fragment />);
  }
  const { classification, observation } = feedback;
  const reviewIcon = () => {
    if (observation) {
      return <img src={GENERAL.starBallon} alt="review" />
    }

    return <img src={GENERAL.starFill} alt="review" />;
  }

  return (
    <div className={`card-corner-container ${styleClass ? styleClass : ''}`}
      style={{ backgroundImage: `url(${GENERAL.cardCorner})` }}>
      <div className="review-container d-inline-flex justify-content-end">
        <div className="review-value"> {classification} </div>
        { reviewIcon() }
      </div>
    </div>
  );
}

export default CardCorner;