import React from 'react';
import './CategoryCard.scss';
import { ASSETS } from '../../../assets/images/images';
import { CategoryCardPropTypes } from '../../../core/models/props-interface.d';

/**
 * @param {Object} props
 * @param {Category} props.category - The category object.
 * @param {Function} props.onClick - A function to handle the click event.
 */
const CategoryCard = (props) => {
  const { category } = props;

  const getIconSrc = () => {
    try {
      return ASSETS.vertical.icon(category?.icon);
    } catch (error) {
      // Fallback if the module is not found
      return ASSETS.vertical.icon('DEFAULT');
    }
  };

  return (
    <div className="category-card align-center" onClick={props.onClick} role="button">
      <div className="category-icon">
        <img 
            src={getIconSrc()}
            alt="category-icon"
            onError={(e) => {
              e.target.onerror = null; // Prevents looping if fallback also fails
              e.target.src = ASSETS.vertical.icon('DEFAULT');
            }}    
        />
      </div>
      <div className="category-name mt-1">{category?.name}</div>
    </div>
  );  
}

CategoryCard.propTypes = CategoryCardPropTypes;
export default CategoryCard;
