//React Imports
import React from 'react';
// Components
import TasksRow from 'components/list/tasks/tasks_row.jsx';
import Button from 'components/button/button.jsx';

// Style
import 'components/list/tasks/tasks.style.scss';
// import 'components/list/tasks/tasks_obs_history.style.scss';
import Labels from '../../../variables/labels';
import Helper from '../../../core/helper/helper';
import Emitter from '../../../core/services';

class TasksList extends React.Component {

  constructor(props) {
    super(props);
    const { data: { totalDiscount }, state, invoiceEmail, title, allowApprove, isAppointment } = props;
    this.state = {
      title: title,
      allowSubmitInvoice: state === 5 && invoiceEmail,
      hasDiscount: totalDiscount && parseFloat(totalDiscount),
      allowApprove: allowApprove,
      working: state === 3,
      analyzing: state === 2,
      approving: state === 2.5,
      isApprovalShinning: false,
      isAppointment: isAppointment,
    };
  }

  componentDidMount() {
    Emitter.on('APPROVAL_SHINNING', () => {
      this.setState({ isApprovalShinning: true });
      setTimeout(() => {
        this.setState({ isApprovalShinning: false });
      }, 1000);
    });
  }

  componentWillUnmount() {
    Emitter.off('APPROVAL_SHINNING');
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { data: { totalDiscount }, state, invoiceEmail, title, allowApprove } = this.props;
      this.setState({
        title: title,
        allowSubmitInvoice: state === 5 && invoiceEmail,
        hasDiscount: totalDiscount && parseFloat(totalDiscount),
        allowApprove: allowApprove,
        working: state === 3,
        analyzing: state === 2,
      });
    }
  }

  
  renderItem = (items) => {
    const hasTaskCompleted = items?.some(i => i.completed);
    if (items) {
      return (
        items.map((value, index) => <TasksRow key={index} taskIndex={index} taskValue={value} hasTaskCompleted={hasTaskCompleted}/> )
        );
      }
    };
    
  renderAproveButton = (total) => {
    const { allowApprove, isApprovalShinning } = this.state;
    if (!allowApprove || !total) { return; }
    const margin = this.state.hasDiscount ? 'mt-2' : 'mt-3'
    return (
      <div className={`mb-3 ${margin}`}>
        <Button className={`cta-button-primary d-sm-large w-100 px-4 ${isApprovalShinning ? 'shinning' : ''}`}
          type="approveButton" nextRow={true} value={Labels.button.approveBudget}
          onClick={() => this.props.onAllowApprove()} />
      </div>
    );
  }

  renderInvoiceButton = () => {
    if (!this.state.allowSubmitInvoice) {
      return;
    }
    const margin = this.state.hasDiscount ? 'mt-2' : 'mt-3'
    return (
      <div className={`mb-3 ${margin}`}>
        <Button className={`cta-button-secondary d-medium d-sm-large w-100 px-4 flex-center`}
          type='button' value={Labels.confirmSendInvoiceButton}
            onClick={() => this.props.submitInvoice()} />
      </div>
    );
  };

  renderDetailsStatus = (data, working, analyzing, approving) => {
    const renderApprovedStatus = () => {
      if (!working) {
        return;
      }
      return <div className='tasks-header-title me-2'><span >Aprovado</span></div>;
    }
    if (analyzing) {
      return <span>em diagnóstico</span>;
    }
    if(approving && !data.total){
      return <span>em aprovação</span>;
    }
    if (!data.total) {
      return <span>em espera</span>;
    }
    return <>{renderApprovedStatus()} {data.total ? `${data.total} €` : ''}</>
  }

  renderTasksHeader = (data) => {
    const { working, analyzing, approving } = this.state;
    const color = working ? 'green' : '';
    return (
      <>
        {(data.total_novat && data.total_novat !== '' && data.total_novat !== '0.00' && data.total_novat !== '0,00') &&
          <>
            <div className={`tasks-header-container small`}>
              <div className='tasks-header-title d-flex align-items-center'>{Labels.totalBudget}
                <span className='ms-1'>{Labels.noIVAValue}</span>
              </div>
              <div className='tasks-header-text d-flex'>{data.total_novat} €</div>

            </div>
          </>
        }
        <div className={`tasks-header-container ${color}`}>
          <div className='tasks-header-title d-flex align-items-center'>{Labels.totalBudget}
            <span className='ms-1'>{Labels.IVAValue}</span>
          </div>
          <div className='tasks-header-text d-flex'>{this.renderDetailsStatus(data, working, analyzing, approving)}</div>
        </div>
      </>
    );
  }

  renderToTalDiscount = (value) => {
    const { hasDiscount, working } = this.state;
    if (!hasDiscount) {
      return;
    }
    const margin = working ? 'mt-2 mb-2' : 'mt-2';
    return (
      <div className={`flex-between tasks-row-text discount ${margin}`}>
        <span>{Labels.totalDiscount}</span>
        <span>-{value} €</span>
      </div>
    );
  }

  renderList = (data) => {
    if (!Helper.objectHasData(data)) {
      return (
        <div className='tasks-empty-list'>
          <div>{Labels.noDetails}</div>
        </div>
      );
    }
    return (
      <div>
        {!this.state.isAppointment && (
          <>
            {this.renderTasksHeader(data)}
            {this.renderToTalDiscount(data.totalDiscount)}
            {this.renderInvoiceButton(data.totalDiscount)}
            {this.renderAproveButton(data.total)}
            <ul className='mb-2'>{this.renderItem(data.items)}</ul>
          </>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="tasks-section-container mt-4 mb-3" id="tasks-section-container">
        <div className='tasks-main-container'>
          {!this.state.isAppointment &&
            <div className='tasks-title mb-2'>{this.state.title}</div>
          }
          {this.renderList(this.props.data)}
        </div>
      </div>
    );
  }
}

export default TasksList;
