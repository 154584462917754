import React, { Component, createRef, Fragment } from "react";
import { getCountriesListData } from "../../api/routes/api.route";
import Helper from "../../core/helper/helper";
import InputField from "../../components/inputField/inputField";
import SelectList from "../../components/SelectComponent/selectList/select-list";
import Cookies from "universal-cookie";
import { BillingFormActive } from "../../core/types";
import PaymentService from "../../core/services/payment.service";
import classNames from "classnames";
import Modal, { Information } from "../../components/modal/modal";
import Labels from "../../variables/labels";

class BillingForm extends Component {
  /**
   * 
   * @param {{onSelectForm: ()=> void, on}} props 
   */
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = createRef(null);
    this.state = {
      hasPaymentActive: props.hasPaymentActive,
      selectedForm: props.hasPaymentActive ? null : BillingFormActive.CLIENT,
      billingOptions: null,
      isButtonDisplayed: false,
      isBillingOptionsLoading: false,
      isFormDisabled: props.hasPaymentActive,
      clientData: {
        countriesList: [],
        billing: props.hasPaymentActive && !!props.billingForm ? props.billingForm : {
          selectedCountry: { id: 175, country: "Portugal" },
          name: "",
          nif: "",
          address: "",
          zip: "",
          city: "",
        },
        customer: {
          selectedCountry: { id: 175, country: "Portugal" },
          name: "",
          nif: "",
          address: "",
          zip: "",
          city: "",
        },
      },
    };
    this.isLiteVersion = Helper.isLiteVersion();
  }

  componentDidMount = () => {
    this.getAdditionalData();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.selectedForm !== this.props.selectedForm) {
      this.setState({ selectedForm: this.props.selectedForm });
    }
    if (prevProps.hasPaymentActive !== this.props.hasPaymentActive) {
      this.setState({
        isFormDisabled: this.props.hasPaymentActive,
        hasPaymentActive: this.props.hasPaymentActive,
        selectedForm: null 
      }, () => this.props.hasPaymentActive && this.updateBillingForm(true));
    }
  };

  getAdditionalData = () => {
    this.getBillingData(!this.state.hasPaymentActive);
    this.getCountriesList();
  };

  updateField = (section, field, value) => {
    this.setState(
      (prevState) => ({
        isButtonDisplayed: true,
        clientData: {
          ...prevState.clientData,
          [section]: {
            ...prevState.clientData[section],
            [field]: value,
          },
        },
      }), this.updateBillingForm );
    this.props.validBillingForm(false);
  };
  
  updateCountry = (section, option) => {
    const country = option ? { id: option.value, country: option.label } : null;
    this.setState(
      (prevState) => ({
        isButtonDisplayed: true,
        clientData: {
          ...prevState.clientData,
          [section]: {
            ...prevState.clientData[section],
            selectedCountry: country,
          },
        },
      }), this.updateBillingForm );
      this.props.validBillingForm(false);
  };
  
  updateBillingForm = (formChanged = false) => {
    const { clientData, selectedForm, hasPaymentActive } = this.state;
    const form = hasPaymentActive && PaymentService.billingFormActive
      ? {
          ...PaymentService.billingFormActive,
          selectedCountry: Helper.getCountryById(clientData.countriesList, PaymentService.billingFormActive?.selectedCountry?.id)
        }
      : PaymentService.billingData?.find(d => d?.id === selectedForm) || {selectedCountry: { id: 175, country: "Portugal" }};

    if (formChanged) {
      this.setState(prevState => ({
        clientData: {
          ...prevState.clientData,
          customer: form?.id === BillingFormActive.CLIENT ? { ...form } : { ...prevState.clientData.customer },
          billing: form?.id === BillingFormActive.CLIENT
            ? !hasPaymentActive
              ? { ...prevState.clientData.billing }
              : { ...form }
            : { ...form },
        }
      }), () => this.updatePServiceBillingForm(selectedForm, this.state.clientData.customer, this.state.clientData.billing, formChanged, form));
    } else {
      this.updatePServiceBillingForm(selectedForm, this.state.clientData.customer, this.state.clientData.billing, formChanged, form);
    }
    
  };

  updatePServiceBillingForm(selectedForm, customer, billing, formChanged, form) {
    PaymentService.billingForm = (() => {
      switch (selectedForm) {
        case BillingFormActive.CLIENT:
          return { ...customer };
        case BillingFormActive.NEW:
          return { ...billing };
        default:
          return formChanged ? { ...form } : { ...billing };
      }
    })();
  }

  buildSelectedCountry = (data) => {
    return data ? { value: `${data.id}`, label: data.country } : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleSelectForm = (value) => {
    if (value !== this.state.selectedForm) {
      this.setState({ selectedForm: value, isButtonDisplayed: false }, () => this.updateBillingForm(true));
      this.props.onSelectForm(value);
    }

    this.props.validBillingForm(
      value !== BillingFormActive.NEW &&
        !PaymentService.billingOptions?.find((o) => value === o?.id)?.isNewClient
    );
  };

  handleSaveBillingData = () => {
    const { clientData: { billing, customer }, selectedForm } = this.state;
    const form = selectedForm === BillingFormActive.CLIENT ? { ...customer } : { ...billing };
    if (PaymentService.billingFormValidation()) {
      if (PaymentService.billingFormNifValidation()) {
        if (PaymentService.billingFormZipCodeValidation()) {
          if (selectedForm === BillingFormActive.NEW) {
            this.saveNewBillingData(form);
          } else {
            this.updateBillingData(form);
          }
        } else {
          this.renderBillingValidation('zipCode');
        }
      } else {
        this.renderBillingValidation('nif');
      }
    } else {
      this.renderBillingValidation();
    }
  };

  // Método para remover uma entrada de dados de faturação
  handleRemoveBilling = (optionToRemove) => {
    this.setState({ isBillingOptionsLoading: true}, () => this.deleteBillingData(optionToRemove));
  };

  // API CALL ################################################################################

  getCountriesList = () => {
    getCountriesListData().then((value) => {
      if (value && value.countries) {
        const countries = value.countries.map((el) => ({ value: el.id, label: el.country }));
        if (this.state.hasPaymentActive) {
          this.setState((prevState) => ({
            clientData: {
              ...prevState.clientData,
              billing: {
                ...prevState.clientData.billing,
                selectedCountry: Helper.getCountryById(countries, this.props.billingForm?.selectedCountry?.id)
              },
              countriesList: countries,
            },
          }));
        } else {
          this.setState((prevState) => ({
            clientData: {
              ...prevState.clientData,
              countriesList: countries,
            },
          }));
        }
      }
    });
  };

  getBillingData = async (updateForm = true) => {
    try {
      this.setState({ isBillingOptionsLoading: true, isButtonDisplayed: false});
      const response = await PaymentService.getBillingData();
      if (response.success) {
        this.setState(prevState => ({
          selectedForm: prevState.selectedForm === BillingFormActive.NEW
            ? PaymentService.billingData[PaymentService.billingData?.length - 1]?.id
            : prevState.selectedForm,
          billingOptions: response.data,
          isBillingOptionsLoading: false,

        }),
        () =>  {
          this.updateBillingForm(updateForm);
          const isNewClient = PaymentService.billingOptions?.find(o => this.state.selectedForm === o?.id)?.isNewClient;
          this.props.validBillingForm(!isNewClient || (isNewClient && !!PaymentService.billingForm?.nif));
        });        
      }
    } catch (error) {
      console.error(error);
      this.setState({ isBillingOptionsLoading: false});
    }
  };

  saveNewBillingData = async (data) => {
    try {
      this.setState({ isBillingOptionsLoading: true});
      const response = await PaymentService.createBillingData(data);
      if (response.success) {

        this.getBillingData(true);
      }
    } catch (error) {
      console.error("saveNewBillingData ERROR", error);
      this.setState({ isBillingOptionsLoading: false});
    }
  };

  updateBillingData = async (data) => {
    try {
      // const { billingOptions } = this.state;
      // if (billingOptions?.length === 1) {
      //   PaymentService.useMock = false;
      // }
      this.setState({ isBillingOptionsLoading: true});
      const response = await PaymentService.updateBillingData(data);
      if (response.success) {
        this.getBillingData(false);
      }
    } catch (error) {
      console.error("updateBillingData ERROR", error);
      this.setState({ isBillingOptionsLoading: false});
    }
  };

  deleteBillingData = async (data) => {
    const { selectedForm } = this.state;
    try {
      const response = await PaymentService.deleteBillingData(data?.id);
      if (response.success) {
        this.setState((prevState) => ({
          billingOptions: prevState.billingOptions.filter(option => option.value !== data.value),
          isBillingOptionsLoading: false
        }));
        if (data?.id === selectedForm) {
          this.handleSelectForm(BillingFormActive.NEW);
        }
      }
    } catch (error) {
      console.error("deleteBillingData ERROR", error);
      this.setState({ isBillingOptionsLoading: false});
    }
  }

  // RENDER SECTION ############################################################

  renderInputField = (section, field, className, title, placeholder, disabled = false) => {
    const { clientData } = this.state;
    return (
      <div className={`inline-column ${className}`}>
        <InputField
          type="text"
          title={title}
          placeholder={placeholder}
          value={clientData[section][field]}
          disabled={disabled}
          styleInput={disabled ? "input-medium-default disabled" : "input-medium-default"}
          onChange={(e) => this.updateField(section, field, e.target.value)}
        />
      </div>
    );
  };

  renderCountryList = (section, field, className, title) => {
    const { clientData, hasPaymentActive } = this.state;
    const selectedOption = this.buildSelectedCountry(clientData[section][field]);
    const options = clientData?.countriesList;
    return (
      <div className={`inline-column ${className}`}>
        <SelectList
          selectedOption={selectedOption}
          title={title}
          isClearable={!hasPaymentActive}
          isDisabled={hasPaymentActive}
          onChange={(option) => this.updateCountry(section, option)}
          options={options}
        />
      </div>
    );
  };

  renderSaveButton = () => {
    const { isButtonDisplayed, isBillingOptionsLoading, selectedForm, billingOptions } = this.state;
    const className = classNames("cta-button-primary w-100 mb-0 mt-4 mt-md-0", {"is-disabled": isBillingOptionsLoading});
    const buttonLabel =
      selectedForm === BillingFormActive.CLIENT || billingOptions?.length === 1
        ? Labels.payment.billing.saveButton.client
        : Labels.payment.billing.saveButton.general;

    return !isButtonDisplayed ? null : (
      <div className={`inline-column col-12 col-md-4 d-flex align-items-end`}>
        <button
          className={className}
          type="button"
          onClick={this.handleSaveBillingData}
          disabled={isBillingOptionsLoading}
        >
          {buttonLabel}
        </button>
      </div>
    );
  }

  renderSectionDetails = () => {
    const { selectedForm, clientData, isFormDisabled, billingOptions } = this.state;

    const title =
      selectedForm === BillingFormActive.CLIENT || billingOptions?.length === 1
        ? "Dados de Cliente"
        : "Dados de Faturação";
    const section = selectedForm === BillingFormActive.CLIENT ? "customer" : "billing";
    const isVatDisabled =
      isFormDisabled ||
      (selectedForm === BillingFormActive.CLIENT &&
        !!clientData.customer?.nif &&
        !PaymentService.billingOptions?.find((o) => selectedForm === o?.id)?.isNewClient) ||
      (section === "billing" && !!clientData.billing?.id);

    return (
      <div className={classNames(
        "grey-box-container mw-100 w-100 mt-3 px-3 pt-2 pt-lg-3",
        { "disabled": isFormDisabled, "mt-5": isFormDisabled })
      }>
        <h2 className="text-center w-100 mb-0">{title}</h2>
        <div className="form-flex-container row">
          {this.renderInputField(section, "name", "col-12 col-md-8", "Nome *", "Nome e Apelido", isFormDisabled)}
          {this.renderCountryList(section, "selectedCountry", "col-12 col-md-4 mt-3 d-md-none", "País *", isFormDisabled)}
          {this.renderInputField(section, "nif", "col-12 col-md-4", "NIF *", "Insira NIF (Ex: 123456789)", isVatDisabled)}
        </div>
        <div className="form-flex-container row">
          {this.renderInputField(section, "address", "col-12 col-md-8", "Morada *", "Inserir Morada", isFormDisabled)}
          {this.renderCountryList(section, "selectedCountry", "col-12 col-md-4 mt-3 d-none d-md-inline-block", "País *", isFormDisabled)}
        </div>
        <div className="form-flex-container row justify-content-start">
          {this.renderInputField(section, "zip", "col-6 col-md-4", "Código Postal *", "Código Postal", isFormDisabled)}
          {this.renderInputField(section, "city", "col-6 col-md-4", "Localidade *", "Localidade", isFormDisabled)}
          {this.renderSaveButton()}
        </div>
      </div>
    );
  };

  renderBillingType = () => {
    const { billingOptions, isFormDisabled } = this.state;
    const renderDropDown = (options) => {
      const selectedOption = options?.find(opt => this.state.selectedForm === opt.value);
      return isFormDisabled || billingOptions?.length === 1 ? null: (
        <div className={`inline-column mt-0 w-100 w-md-50`}>
          <SelectList
            options={options}
            selectedOption={selectedOption}
            title={"Selecione dados de faturação"}
            isClearable={false}
            isRemovable={true}
            isLoading={this.state.isBillingOptionsLoading}
            isDisabled={isFormDisabled}
            onChange={(opt) => this.handleSelectForm(opt.value)}
            onRemoveOption={this.handleRemoveBilling}
          />
        </div>
      );
    };
    
    // return renderRadioOptions(billingOptions);
    return renderDropDown(billingOptions);
  };

  renderBillingValidation = (isField = false) => {
    this.renderModal(
      <Information
        title="Aviso"
        text={isField === 'nif' 
          ? Labels.payment.billing.validation.nif 
          : isField === 'zipCode'
            ? Labels.payment.billing.validation.zipCode
            : Labels.payment.billing.validation.empty}
        onClick={() => this.refModal.current.closeModal()}
      />
    );
  };
  
  render() {
    return (
      <Fragment>
        {this.renderBillingType()}
        <form onSubmit={this.handleSubmit}>
          {this.renderSectionDetails()}
        </form>
        <Modal ref={this.refModal} />
      </Fragment>
    );
  }

  renderModal = (view, isMedia = false, occupyScreen = false) => {
    this.refModal.current.renderView(view, isMedia, occupyScreen);
    this.refModal.current.openModal();
  }
}


  //   return (
  //     <div className="ms-3">
  //       {options?.map((option, index) => (
  //         <label key={index} className={`mt-${index > 0 ? 2 : 0} inline-flex-start w-100`} role="button">
  //           <input
  //             type="radio"
  //             checked={this.state.selectedForm === option.value}
  //             onChange={() => this.handleSelectForm(option.value)}
  //             disabled={false}
  //           />
  //           <div className="ms-2 inline-flex-start w-100">
  //             <span>{option.label}</span>
  //           </div>
  //         </label>
  //       ))}
  //     </div>
  //   );
  // };

export default BillingForm;
