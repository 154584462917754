// React Imports
import React from 'react';
import StarRatings from 'react-star-ratings';
import { withRouter } from "react-router-dom";
//import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
// Components Imports
import Button from 'components/button/button.jsx';
import Modal, { Information } from 'components/modal/modal.jsx';
// API Imports
import { submitApplicationFeedback } from 'api/routes/api.route.js';
import { Helmet } from 'react-helmet';
import Helper from '../../core/helper/helper';
import Labels from '../../variables/labels';
import IAgreeBox from '../../components/iagree/iagreeBox';
import LocalData from '../../core/localData';

class FeedbackApp extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.refInput = React.createRef();
    this.refRadioInput = React.createRef();
    this.state = {
      value: 0
    }
//    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  /**
    * Pedido de submissão do rating da aplicação inserido pelo o utilziador
  */
  handleFeedbackSubmit = (event) => {
    event.preventDefault();
    const APP_RATING = {
      classification: this.state.value,
      observation: this.refInput.current.value
    }
    submitApplicationFeedback(this.cookies.get('sessionToken'), APP_RATING).then(value => {
      if (value) {
        if (value.code) {
          if (value.code === 401) {
            Helper.callLogError('401 submitApplicationFeedback ' + this.cookies.get("sessionToken") + ' ');
            return;
          } else if (value.code === 400) {
            this.refModal.current.renderView(
              <Information
                title='Dados Incorrectos'
                text={value.message}
                onClick={() => this.refModal.current.closeModal()} />);
            this.refModal.current.openModal();
          } else {
            this.refModal.current.renderView(
              <Information
                title='Pedido Sem Sucesso'
                text='Foi encontrado um problema durante o pedido, por favor tente mais tarde'
                onClick={() => this.refModal.current.closeModal()} />);
            this.refModal.current.openModal();
          }
        } else {
          this.refModal.current.renderView(
            <Information
              title='Feedback Enviado'
              text={value.message}
              onClick={() => this.props.history.push(`/`)} />);
          this.refModal.current.openModal();
        }
      } else {
        this.refModal.current.renderView(
          <Information
            title='Pedido Sem Sucesso'
            text='Foi encontrado um problema durante o pedido, por favor tente mais tarde'
            onClick={() => this.refModal.current.closeModal()} />);
        this.refModal.current.openModal();
      }
    });

  }

  /**
    * Valor do rating inserido pelo o utilizador
    *
    * @param {number} value - valor do rating inserido
  */
  handleRatingValue = (value) => {
    this.setState({ value: value });
  }

  renderRatingContainer = () => {
    return(
      <div className="rating-container">
        <div className="rate-service">
          <StarRatings
            rating={this.state.value}
            numberOfStars={5}
            name={`rating_${this.state.id}`}
            starRatedColor="#00A9FD"
            starEmptyColor="rgba(165, 165, 165, 0.4)"
            starHoverColor="#00A9FD"
            starDimension="30px"
            starSpacing="8px"
            svgIconViewBox="0 0 24 24"
            svgIconPath="m12 18.375-6.613 3.476 1.263-7.363L1.3 9.274l7.394-1.075L12 1.5l3.306 6.7L22.7 9.273l-5.35 5.214 1.264 7.363z" 
            changeRating={this.handleRatingValue}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <IAgreeBox url={window.location} />
        {LocalData.terms_accepted && (
          <div className="main-container px-3 pb-4">
            <form className="w-100" onSubmit={this.handleFeedbackSubmit}>
              <div className="grey-box-container w-100 mt-5 pb-4" style={{ maxWidth: "652px" }}>
                <h2 className="text-center w-100 mb-0">{Labels.appFeedback.header} </h2>
                <div className="text-center mt-0 v-text">{Helper.appVersion}</div>
                <div className="message-container mt-4"> {Labels.appFeedback.message} </div>
                <div className="box-form-container mt-3">
                  {this.renderRatingContainer()}
                  <textarea
                    ref={this.refInput}
                    rows="5"
                    className="text-area-container-big mt-4 mt-lg-5"
                    placeholder="Observações"
                    maxLength="2000"
                  />
                </div>
              </div>
              <Button
                className="cta-button-primary d-medium w-100 mt-4"
                style={{ maxWidth: "427px" }}
                type="submit"
                value="Enviar"
              />
            </form>
            <Modal ref={this.refModal} />
            <Helmet>
              <title> {Helper.titleFeedbackPage()} </title>
            </Helmet>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(FeedbackApp);