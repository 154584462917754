import React from "react";
import { components } from "react-select";
import { GENERAL } from "../../../../assets/images/images";

/**
 * @param {Object} props - The props for the DropdownIndicator component.
 * @param {React.FC<DropdownIndicatorProps>} props.children - The children components.
 */
const SelectIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={GENERAL.iconSmallArrowDown} alt='icon-select'></img>
    </components.DropdownIndicator>
  );
};

export default SelectIndicator;
