import React, { useState, useEffect, Fragment } from "react";
import SelectComponent from "../selectComponent/SelectComponent";

const cLabels = {
  listTitle: "Serviços solicitados",
  observations: "Os seus comentários/observações:",
  placeholderObservations: "Serviços aqui adicionados não serão considerados para efeitos de agendamento",
  selectSpecialist: "Escolha um especialista:",
  placeholderSpecialist: "- Qualquer especialista -"
};

/**
 * @param {Object} props
 * @param {scheduleData} props.scheduleData
 * @param {Function} props.onNewObservations
 * @param {Function} props.onSelectedExpert
 */
const ScheduleServiceResumeCard = (props) => {
  const [scheduleData, setScheduleData] = useState(props.scheduleData);
  const [observations, setObservations] = useState(props.scheduleData?.serviceObservations);
  const [services, setServices] = useState(props.scheduleData?.selectedServices);
  const [expertSelected, setExpertSelected] = useState(props.scheduleData?.expertSelected);
  const [isDropdownOpen, setIsDropdownOpen] = useState(undefined); // State to control dropdown
  const [expertsBySlot, setExpertsBySlot] = useState(props.scheduleData?.expertsBySlot);

  useEffect(() => {
    // in step 3 an expert is mandatory if symptom has experts
    if (!expertSelected)setIsDropdownOpen(true); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setExpertsBySlot(props.scheduleData?.expertsBySlot);
  }, [props.scheduleData.expertsBySlot]);

  useEffect(() => {
    setExpertSelected(props.scheduleData?.expertSelected);
  }, [props.scheduleData.expertSelected]);

  useEffect(() => {
    setServices(props.scheduleData.selectedServices);
}, [props.scheduleData.selectedServices]);

  useEffect(() => {
    setObservations(props.scheduleData?.serviceObservations);
  }, [props.scheduleData]);

  useEffect(() => {
    setScheduleData(props.scheduleData);
  }, [props.scheduleData]);

  const handleObsChange = (event) => {
    setObservations(event.target.value);
    props.onNewObservations(event.target.value)
  }

  const renderSymptomsList = () => {
    return scheduleData?.selectedServices?.map((s, i) => {
      return (
        <div className="symptom-row mt-2" key={i}>
          <div className="bullet-point"></div>
          <div className="ms-3 symptom-name">
            {s.name} 
            {s.obs_value && `(${s.obs_value} ${s.obs_label})`} 
            {s.schedule_payment_value && (<strong> {s.schedule_payment_value.toString().replace('.', ',')} €</strong>)}
          </div>
        </div>
      );
    });
  };

  const renderExpert = () => {
    if (!services || !services.length ||
      !services.some(s => s.active && !s.disabled && s.users && s.users.length > 0)) {
      return <Fragment />;
    }
    const buildExpertList = (services) => {
      const expertMap = new Map();
      services.forEach(service => {
        if (service.active && !service.disabled) {
          service.users.forEach(user => {
            if (!expertMap.has(user.id)) {
              expertMap.set(user.id, { value: user.id, label: user.name });
            }
          });
        }
      });
      const experts = Array.from(expertMap.values());
      //Do not add option to clear expert (mandatory field on step 3)
      //experts.unshift({ value: 'clear_selection', label: cLabels.placeholderSpecialist }); // Add at the start of the list
      return experts;
    };

    const filterExpertsBySlot = (options) => {

      if(!expertsBySlot) return null;

      // Convert all user IDs to strings for consistent comparison
      const userIDs = expertsBySlot.users.map(id => id.toString());
      // Filter options to include only those whose value is in the userIDs array
      const filteredOptions = options.filter(option => userIDs.includes(option.value));
      return filteredOptions;
    }

    let expertsAvailable = buildExpertList(services);
    expertsAvailable = filterExpertsBySlot(expertsAvailable);

    const handleChange = (selectedOption) => {
      if (selectedOption && selectedOption.value !== 'clear_selection') {
        // Set expertSelected to the selected option's corresponding user object
        props.onSelectedExpert({
          id: selectedOption.value,
          name: selectedOption.label
        });
        setIsDropdownOpen(undefined)
      } else {
        // Clear the selection when the clear option is chosen
        props.onSelectedExpert(null);
      }

    };

    if (!expertsAvailable || props.isLockedToStep3){
      return;
    }

    if (((props.isLockedToStep2 ) && expertSelected)
        || (expertSelected && expertsAvailable.length === 1)) {
      return (
        <div className="mt-3">
          <div className="input-label regular">Especialista: {expertSelected?.name}</div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="observations-container w-100 mt-4">
          <SelectComponent
            menuIsOpen={isDropdownOpen} 
            selectedOption={expertSelected ? { value: expertSelected.id, label: expertSelected.name } : null}
            onChange={handleChange}
            options={expertsAvailable}
            config={{
              isMulti: false,
              isClearable: false,
              autoFocus: false,
              isSearchable: true
            }}
            label={cLabels.selectSpecialist}
            />
        </div>
      </React.Fragment>
    );
  }

  const renderObservations = () => {
    return (
      <Fragment>
        <div className="observations-container w-100 mt-4">
          <div className="observations-label normal-regular-black mb-1 d-flex align-left">
            {" "}
            {cLabels.observations}{" "}
          </div>
          <textarea
            defaultValue={observations}
            maxLength="2000"
            onChange={handleObsChange}
            rows={4}
            className={`w-100 km-text-area active noresize`}
            placeholder={cLabels.placeholderObservations}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="w-100 mw-100 grey-box-container mt-4 d-flex justify-content-start flex-column">
        <h3 className="d-flex mt-2">{cLabels.listTitle}</h3>
        <div className="schedule-symptoms-list-resume d-flex flex-column justify-content-start mt-2">
          {renderSymptomsList()}
          {renderExpert()}
          {renderObservations()}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScheduleServiceResumeCard;
