import React, { Component, Fragment, createRef } from "react";
import { withRouter } from "react-router-dom";
import "./Schedule.scss";
import Step1Schedule from "./components/stepsContent/Step1Schedule";
import ScheduleService from "./core/services/schedule.service";
import ScheduleTopInformation from "./components/ScheduleTopInformation";
import Step2Schedule from "./components/stepsContent/Step2Schedule";
import ScheduleCTA from "./components/ScheduleCTA";
import Step3Schedule from "./components/stepsContent/Step3Schedule";
import ScheduleConfirmation from "./components/ScheduleConfirmation";
import Modal, { Confirmation, Information } from "../../components/modal/modal";
import Helper from "../../core/helper/helper";
import { format, addDays, subDays, startOfMonth, endOfMonth, addMonths, subMonths } from "date-fns";
import Emitter from "../../core/services";
import MediaFiles from "../../components/mediaFiles/MediaFiles";
import Labels from "../../variables/labels";
import Loader from "../../components/loader/loader";
import { addItemToUser, addMediaFile, getItemList, removeMediaFile } from "../../api/routes/api.route";
import Cookies from 'universal-cookie';
import LocalData from "../../core/localData";
import LoginModal from "../../components/modal/LoginModal";
import CompanyService from "../../core/services/company.service";
import { GENERAL } from "../../assets/images/images";
import IAgreeBox from "../../components/iagree/iagreeBox";

const preferedTimeOptions= [
  {
      "value": 1,
      "label": "Manhãs"
  },
  {
      "value": 2,
      "label": "Tardes"
  },
  {
      "value": 3,
      "label": "Todo o dia"
  }
];

class SchedulePage extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = createRef();
    this.continueButtonRef = createRef();
    this.isLiteVersion = Helper.isLiteVersion();
    this.query = this.props.location.search;
    const params = new URLSearchParams(this.query);
    this.token = params.get("token") || LocalData.liteData?.token;
    this.token_cal = params.get("token_cal") || LocalData.liteData?.token_cal;
    
    this.savedState = localStorage.getItem("appState");
    this.state = {};
    if (this.savedState && JSON.parse(this.savedState).data?.domain === Helper.companyDomain()) {
      const { data, timestamp } = JSON.parse(this.savedState);
      const currentTime = new Date().getTime();
      // Check if the saved state is still valid (within 1 hour)
      if (currentTime - timestamp < 3600000) {
        if (data?.scheduleData?.selectedDate) {
          data.scheduleData.selectedDate = new Date(data.scheduleData.selectedDate);
        }
        if (data?.scheduleData?.displayedDate) {
          data.scheduleData.displayedDate = new Date(data.scheduleData.displayedDate);
        }
        this.state = data; // Set the state from the saved data
      } else {
        this.state = this.loadDefaultState();
      }

      // Clear the state from localStorage after usage
      localStorage.removeItem("appState");
    } else {
      if (this.props.location.pathname.toString()?.includes("payment")) {
        this.state = { ...this.loadDefaultState(), stepActive: 3 };
      } else {
        this.state = this.loadDefaultState();
      }
    }
  }

  loadDefaultState() {
    return {
      domain: Helper.companyDomain(),
      token: this.token,
      token_cal: this.token_cal,
      showLoginModal: false,
      actionCloseGallery: false,
      config: Helper.getConfig(),
      scheduleConfirmed: false,
      /** @type {1 | 2 | 3} */
      stepActive: 1, // 1 | 2 | 3
      /** @type {{ id: string, name: string, active: boolean}[]} */
      services: undefined,
      /** @type {SchedulerConfig} */
      schedulerConfig: undefined,
      /**@type {ScheduleData} */
      scheduleData: {
        schedulerId: undefined,
        draftWorkID: undefined,
        itemDetails: undefined,
        selectedServices: [],
        serviceObservations: undefined,
        selectedDate: undefined,
        preferedTime: {
          value: 3,
          label: "Todo o dia",
        },
        selectedTime: undefined,
        timeSlots: undefined,
        displayedDate: new Date(),
        expertSelected: undefined,
        considerFavoriteExpert: true,
        expertsBySlot: undefined,
        files: {
          provider: {
            media: [],
            documents: [],
          },
          customer: {
            media: [],
            documents: [],
          },
        },
      },
      isDisabledScheduleConfirmation: false,
      continueButtonVisible: undefined,
      continueEnabled: undefined,
      isLockedToStep2: false,
      isLockedToStep3: false,
    };
  }

  componentDidMount() {
    if (this.state.config?.scheduler_module !== "FULL") {
      if (!this.isLiteVersion) {
        this.props.history.push("/");
      } else if (this.token) {
        this.props.history.push(`/services-lite?token=${this.token}`);
      } else if (this.token_cal) {
        this.props.history.push(`/services-lite?token_cal=${this.token_cal}`);
      } else {
        this.props.history.push("/services-lite");
      }
    }
    Emitter.emit("TOAST_LITEVERSION_DISABLE", true);
    // Check if the data exists in sessionStorage
    if (localStorage.getItem("repeatAppointment")) {
      // Retrieve the repeatAppointment object from sessionStorage
      const repeatAppointmentString = localStorage.getItem("repeatAppointment");
      const repeatAppointment = JSON.parse(repeatAppointmentString);
      //console.log("repeatAppointment: ", repeatAppointment);
      // Delete the key from sessionStorage
      localStorage.removeItem("repeatAppointment");
      this.setState(
        (prevState) => ({
          stepActive: 1,
          scheduleData: {
            ...prevState.scheduleData,
            selectedServices: repeatAppointment.symptoms.slice(),
            serviceObservations: repeatAppointment.obs,
          },
        }),
        () => {
            if (!LocalData.equipmentsData?.length) {
              //this.callGetItemsApi();
            }
//          this.getItemDetails(() => {
            this.createDraftSchedule();
            //console.log("this.state.scheduleData", this.state.scheduleData);
            
            this.getServices();
//          });
        }
      );
    } else {
      //console.log("No repeatAppointment found in sessionStorage");
      //console.log("Localdata.equipmentsData", LocalData.equipmentsData);
      //console.log("Localdata.terms_accepted", LocalData.terms_accepted);

      //console.log("LocalData.equipmentsData", LocalData.equipmentsData);
        if (!LocalData.equipmentsData?.length) {
          //this.callGetItemsApi();
        }
      // Handle the case when the data is not found in sessionStorage
//      this.getItemDetails(() => {
        this.createDraftSchedule();
        //console.log("this.state.scheduleData", this.state.scheduleData);

        (async () => {
          await this.getServices();
          this.checkForAppointmentParams();
        })();
//      });
    }

    Emitter.on("ENABLE_SCROLL_CONTINUE", (enable) => {
      if (this.state.continueEnabled === enable) return;
      this.setState({ continueEnabled: enable });
      //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!! ENABLE_SCROLL_CONTINUE", enable);
    });
    window.addEventListener("scroll", this.handleScrollContinueButton);
    setTimeout(() => {
      this.handleScrollContinueButton(); // show gallery button before scroll
    }, 400);

    Emitter.on("SCHEDULE_BACK", () => {
      const { stepActive } = this.state;
      if (stepActive === 1) {
        window.history.back();
      } else {
        if ((this.state.isLockedToStep2 && stepActive === 2) || (this.state.isLockedToStep3 && stepActive === 3)) {
          return;
        }
        if (this.state.scheduleData?.selectedServices?.some(service => service.schedule_fixed_start_enabled) && stepActive === 3) {
          this.handleStepChanged(stepActive - 2);
          return;
        }
        this.handleStepChanged(stepActive - 1);
      }
    });

    //If url has submit=true , submit the schedule automatically.
    if (new URLSearchParams(this.query).get("submit") === "true") {
      this.handleScheduleConfirmation();
    }
  }

  componentDidUpdate(prevProps) {
    // Check if the URL has changed
    if (this.props.location !== prevProps.location) {
      // URL has changed, reload or reinitialize component logic
      this.handleUrlChange();
    }
  }

  handleUrlChange() {
    // Check if the data exists in sessionStorage
    if (localStorage.getItem("repeatAppointment")) {
      // Retrieve the repeatAppointment object from sessionStorage
      const repeatAppointmentString = localStorage.getItem("repeatAppointment");
      const repeatAppointment = JSON.parse(repeatAppointmentString);
      // Delete the key from sessionStorage
      localStorage.removeItem("repeatAppointment");
      this.setState(
        (prevState) => ({
          stepActive: 1,
          scheduleData: {
            ...prevState.scheduleData,
            selectedServices: repeatAppointment.symptoms.slice(),
            serviceObservations: repeatAppointment.obs,
          },
        }),
        () => {
          this.getItemDetails(() => {
            if (!this.state.scheduleData?.schedulerId || !this.state.scheduleData?.schedulerId) {
              this.createDraftSchedule();
            }
          });
        }
      );
    } else {
      // Handle the case when the data is not found in sessionStorage
      this.getItemDetails(() => {
        if (!this.state.scheduleData?.schedulerId || !this.state.scheduleData?.schedulerId) {
          this.createDraftSchedule();
        }
      });
    }

  }

  componentWillUnmount() {
    Emitter.off("SCHEDULE_BACK");
    Emitter.off("ENABLE_SCROLL_CONTINUE");
    window.removeEventListener("scroll", this.handleScrollContinueButton);
  }

  checkForAppointmentParams = async () => {
    if (new URLSearchParams(this.query).get("directAppointment") === "true" && new URLSearchParams(this.query).get("details")) {
      const detailsParam = new URLSearchParams(this.query).get("details");
      const details = await ScheduleService.getAppointmentDetailsFromParams(detailsParam);

      if (!details) {
        console.error("Could not parse details from URL");
        return;
      }
      const preloadedServices = this.state.services.filter(service => service.fo_id === details.symptom_id);

      if (preloadedServices?.length === 0) {
        console.error("Could not find symptom that is currently active");
        return;
      }

      //if preloadedServices has a service without any users, then we can't find the expert. Continue without expert.
      if (preloadedServices.find(service => !service.users || service.users.length === 0)) {
        const updatedServices = this.state.services.map(service =>
          service.id === preloadedServices[0].id
            ? { ...service, active: true }
            : service
        );
        this.handleSelectedServices(updatedServices);
        if(preloadedServices[0].schedule_fixed_start_enabled) {
          this.handleStepChanged(3);
          this.setState({ isLockedToStep3: true });
        } else {
          this.handleStepChanged(2);
          this.setState({ isLockedToStep2: true });
        }
        return;
      }

      const preloadedServicesAndExpert = preloadedServices.find(service => service.users && service.users.find(user => user.id === details.user_id));

      if (preloadedServicesAndExpert) {
        const updatedServices = this.state.services.map(service =>
          service.id === preloadedServicesAndExpert.id
            ? { ...service, active: true }
            : service
        );
        this.handleSelectedServices(updatedServices);
        const expertUser = preloadedServicesAndExpert.users.find(
          user => user.id === details.user_id
        );
        this.handleSelectedExpert(expertUser);
        if(preloadedServicesAndExpert.schedule_fixed_start_enabled) {
          this.handleStepChanged(3);
          this.setState({ isLockedToStep3: true });
        } else {
          this.handleStepChanged(2);
          this.setState({ isLockedToStep2: true });
        }

      } else {
        console.error("Could not find pair symptom,expert that is currently active");
      }
    }
  }

  getAppointmentFiles = async () => {
    const { schedulerId, draftWorkID } = this.state.scheduleData;
    const isDraft = true;
    try {
      const response = await ScheduleService.fetchAppointmentFiles(
        "S-" + schedulerId,
        draftWorkID,
        isDraft
      );
      if (response?.data) {
        this.setState((prevState) => ({
          scheduleData: {
            ...prevState.scheduleData,
            files: {
              provider: response.data.company_extras,
              customer: response.data.client_extras,
            },
          },
        }));
      } else {
        this.renderInfoModal(response?.title, response?.body, response?.code);
      }
    } catch (error) {
      console.error("fetchAppointmentFiles [catch error]", error);
    }
  };

  // callGetItemsApi() {
  //   if (LocalData.terms_accepted) {
  //     //console.log("Calling getItemList");
  //     getItemList(this.cookies.get("sessionToken")).then((value) => {
  //       if (value) {
  //         if (value.code) {
  //           //console.log("ERROR", value);
  //         } else {
  //           const items = value.items;
  //           setTimeout(() => {
  //             LocalData.equipmentsData = items;
  //             //console.log("LocalData.equipmentsData", LocalData.equipmentsData);
  //           }, 500);
            
  //         }
  //       } else {
  //           //console.log("ERROR", value);
  //       }
  //     });
  //   }
  // }

  createDraftSchedule = async () => {
    const { scheduleData } = this.state;
    if (scheduleData?.schedulerId && scheduleData?.draftWorkID) {
      return null;
    }
    try {
      const response = await ScheduleService.postCreateDraftSchedule(scheduleData);
      if (response?.success) {
        this.setState((prevState) => ({
          scheduleData: {
            ...prevState.scheduleData,
            schedulerId: response.scheduler_id,
            draftWorkID: response.draft_work_id,
          },
        }));
        //console.log("setting draftWorkID", response.draft_work_id);
        //console.log("setting schedulerId", response.scheduler_id);
      } else {
        this.renderInfoModal(response?.title, response?.body, 'home');
      }
    } catch (error) {
      console.error("handleScheduleConfirmation [catch error]", error);
    }
  };

  getItemDetails = async (callback) => {
    try {
      const { scheduleData } = this.state;
      const { match, history } = this.props;
      const equipId = match.params.id;

      // Function to handle redirection
      const redirectToLiteService = () => {
        if (this.token) {
          history.push(`/services-lite?token=${this.token}`);
        } else if (this.token_cal) {
          history.push(`/services-lite?token_cal=${this.token_cal}`);
        } else {
          history.push("/services-lite");
        }
      };

      // Early return if we are in lite version and have equipment data
      if (this.isLiteVersion) {
        const { equipmentsData } = LocalData;

        if (equipmentsData?.length > 0 && equipId) {
          const matchedItem = equipmentsData.find((item) => item.id === equipId);

          if (matchedItem) {
            const { id, title, subtitle, image, photo, ...specs } = matchedItem;
            const itemDetails = {
              itemFoId: id,
              title,
              subtitle,
              image,
              photo,
              ...specs,
            };
            this.setState(prevState => ({
              scheduleData: { ...prevState.scheduleData, itemDetails },
            }));
          } else {
            redirectToLiteService(); // Handle the redirection if no matched item
          }
        } else {
          redirectToLiteService(); // Handle the redirection if no equipment data
        }
        this.getServices(); // Continue to fetch services after the redirection

        if (callback) callback();

        return;
      }

      // For non-lite version: Fetch item details from the service
      if (!scheduleData?.itemDetails) {
        //console.log("Fetching item details... ", equipId);
        const itemDetails = await ScheduleService.fetchItemDetails(equipId);
        this.setState(prevState => ({
          scheduleData: { ...prevState.scheduleData, itemDetails },
        }));
        // this.addMockExpertFavorites(); (Uncomment if needed)
      }

      this.getServices(); // Fetch services for non-lite version

      if (callback) callback();

    } catch (error) {
      console.error(error);
    }
  };

  getExpertsBySlot = async () => {
    try {
      const { scheduleData } = this.state;
      const expertsBySlot = await ScheduleService.fetchExpertsBySlot(scheduleData);
      if (expertsBySlot?.users) {
        this.setState(prevState => ({
          scheduleData: { ...prevState.scheduleData, expertsBySlot },
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  addMockExpertFavorites = () => {
    const users = ["1"];

    // Retrieve the current itemDetails from the state
    const itemDetails = this.state.scheduleData.itemDetails;

    // Create a new favorites array combining old favorites and mockFavorites
    const updatedFavorites = [...itemDetails.users, ...users];

    // Update the state with the new favorites array
    this.setState((prevState) => ({
      scheduleData: {
        ...prevState.scheduleData,
        itemDetails: {
          ...prevState.scheduleData.itemDetails,
          users: updatedFavorites,
        },
      },
    }));
  };

  addMockSpecialistsToServices = () => {
    // Logging initial services

    // Define the experts for the respective IDs
    const expertsMapping = {
      351: [
        { id: 1, name: "Joaquim Fernandes" },
        { id: 2, name: "Clara Costa" },
      ],
      452: [
        { id: 3, name: "Isabel Lopes" },
        { id: 2, name: "Clara Costa" },
      ],
    };

    // Update the services in the state
    const updatedServices = this.state.services.map((service) => {
      // If the service ID is in the expertsMapping, add the experts array
      if (expertsMapping[service.id]) {
        return { ...service, users: expertsMapping[service.id] };
      }
      // Otherwise, return the service as is
      return service;
    });

    // Update the state with the modified services
    this.setState(prevState => ({
      ...prevState,
      services: updatedServices,
    }));
    
  };

  getServices = async () => {
    try {
      const services = await ScheduleService.fetchScheduleServices();
      this.setState(prevState => ({
        ...prevState,
        services,
      }));
      //this.addMockSpecialistsToServices();
    } catch (error) {
      console.error(error);
    }
  };

  handleSelectedServices = (toUpdateServices) => {
    // Map over current services and merge `notAvailable` where necessary
    const updatedServices = this.state.services.map((service) => {
      const matchingService = toUpdateServices.find((s) => s.id === service.id);
      return matchingService
        ? { ...service, notAvailable: matchingService.notAvailable ?? false }
        : service;
    });

    const selectedServices = toUpdateServices?.filter((s) => s.active);
    const hasFixedStartEnabled = selectedServices?.some((service) => service.schedule_fixed_start_enabled);
    // Symptoms with fixed start datetime should reset the selectedDate and selectedTime that is set in step 2
    if (hasFixedStartEnabled) {
      this.setState((prevState) => ({
        scheduleData: {
          ...prevState.scheduleData,
          selectedDate: undefined,
          selectedTime: undefined,
        },
      }));
    }

    this.setState(prevState => ({
      services: updatedServices,
      scheduleData: {
        ...prevState.scheduleData,
        selectedServices,
      },
      considerFavoriteExpert: true,
    }));    
  };

  /**
   *
   * @param {{ id: string, name: string, active: boolean}[]} services
   * @param { timePreference: int}
   * @param { displayedDate: date}
   */
  getSchedulerConfig = async (services, timePreference, displayedDate) => {
    const symptoms = services?.map((s) => s?.id);
    let start = subDays(startOfMonth(subMonths(displayedDate, 1)), 8);
    if (start < new Date()) start = new Date();
    start = format(start, "dd/MM/yyyy");
    let end = addDays(endOfMonth(addMonths(displayedDate, 1)), 8);
    if (end < new Date()) end = new Date();
    end = format(end, "dd/MM/yyyy");

    if (symptoms.length === 0) return;
    try {
      const schedulerConfig = await ScheduleService.fetchSchedulerConfig(
        symptoms,
        timePreference,
        start,
        end,
        this.state.scheduleData.expertSelected?.id
      );
      this.setState({ schedulerConfig });
    } catch (error) {
      console.error(error);
    }
  };

  handleSelectedExpert = (expertSelected) => {
    this.setState((prevState) => ({
      scheduleData: {
        ...prevState.scheduleData,
        expertSelected: expertSelected, // Update expertSelected with the new value
      },
    }));
  };

  handleNewObservations = (serviceObservations) => {
    this.setState({
      scheduleData: { ...this.state.scheduleData, serviceObservations },
    });
  };

  clientDataUpdated = (newData) => {
    this.setState({
      scheduleData: { ...this.state.scheduleData, client: newData },
    });
  };

  handleSelectedDate = (selectedDate, preferedTime) => {
    this.setState({
      scheduleData: {
        ...this.state.scheduleData,
        preferedTime,
        selectedDate,
        selectedTime: undefined,
        timeSlots: undefined,
      },
    });
  };

  handleChangeDisplayedDate = (displayedDate) => {
    this.setState({
      scheduleData: { ...this.state.scheduleData, displayedDate },
    });
    this.getSchedulerConfig(
      this.state.scheduleData.selectedServices,
      this.state.scheduleData.preferedTime.value,
      displayedDate
    );
  };

  handlePreferedTime = (preferedTime) => {
    this.setState({
      scheduleData: { ...this.state.scheduleData, preferedTime, selectedTime: undefined },
    });
    this.getSchedulerConfig(
      this.state.scheduleData.selectedServices,
      preferedTime.value,
      this.state.scheduleData.displayedDate
    );
  };

  handleSelectedTime = (selectedTime, timeSlots) => {
    this.setState({
      scheduleData: {
        ...this.state.scheduleData,
        selectedTime,
        timeSlots,
      },
    });
  };

  updateServicesClearTemporary = (callback) => {
    const { selectedServices } = this.state.scheduleData;
    const hasServiceWithExpert = selectedServices.some(
      (service) => service.users && service.users.length > 0
    );

    if (hasServiceWithExpert) {
      const filteredServices = selectedServices.filter(
        (service) => service.users && service.users.length > 0
      );

      if (filteredServices.length !== selectedServices.length) {
        this.setState(
          (prevState) => ({
            scheduleData: {
              ...prevState.scheduleData,
              selectedServices: filteredServices,
            },
          }),
          callback
        );
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  handleStepChanged = (step) => {
    const { stepActive, service, scheduleData } = this.state;
    const services = scheduleData?.selectedServices || [];

    // Helper function to determine if there are valid expert services
    const hasExpertServices = () =>
      services.some((s) => s.active && !s.disabled && s.users && s.users.length > 0);

    // Step 1 -> Step 2 and no services
    if (stepActive === 1 && step === 2 && (!service || service.length === 0)) {
      this.updateServicesClearTemporary(() => {
        // Callback logic after state update (commented out for now)
        // this.getSchedulerConfig(scheduleData.selectedServices, scheduleData.preferedTime.value, scheduleData.displayedDate);
      });
    }

    // Step 2 -> Step 3 and has expert services
    if (
      stepActive === 2 &&
      step === 3 &&
      (!service || service.length === 0) &&
      hasExpertServices()
    ) {
      this.getExpertsBySlot();
    }

    // Reset scheduleData when moving back to Step 1 or Step 2
    if (step === 1 || step === 2) {
      this.setState((prevState) => ({
        scheduleData: { ...prevState.scheduleData, client: undefined }, // Remove client from scheduleData
      }));
    }

    // Set the new active step
    this.setState({ stepActive: step });
  };

  updateClientData = () => {
    const userData = LocalData.userData;
    const clientDataToUpdate = this.state.scheduleData.client;
    if (userData && clientDataToUpdate) {
      Object.keys(clientDataToUpdate).forEach((key) => {
        const value = clientDataToUpdate[key];
        if (value === null) {
          if (key === "secondary_email" || key === "name") {
            userData[key] = "";
          } else if (key === "invoice_primary" || key === "invoice_secondary") {
            userData[key] = false;
          }
        } else {
          userData[key] = value;
        }
      });
    }
  };

  addItem = async (equipData) => {
    const newItem = Helper.buildEquipmentSpecsBody(equipData);
    if (Helper.isLiteVersion()) {
      // Handle lite version if needed
      return;
    } else {
      //console.log("Adding item to user", newItem);

      const value = await addItemToUser(this.cookies.get("sessionToken"), newItem);
      if (value) {
        if (value.code) {
          if (value.code === 401) {
            Helper.callLogError("401 addItem " + this.cookies.get("sessionToken") + " ");
            return;
          }
          if (value.code === 409) {
            //console.log("ERROR", value.message);
            // Modal.create(
            //   <Information
            //     title={Labels.registedErrorTitle}
            //     text={value.message}
            //     onClick={() => Modal.close()}
            //   />
            // );
          } else if (value.code === 429) {
            //console.log("ERROR", value.message);
            // Modal.create(
            //   <Information
            //     title={Labels.registedErrorTitle}
            //     text={value.message}
            //     onClick={() => Modal.close()}
            //   />
            // );
          } else {
            //console.log("ERROR", value.message);
            // Modal.create(
            //   <Information
            //     title={Labels.registedErrorTitle}
            //     text={Labels.genericErrorMessage}
            //     onClick={() => Modal.close()}
            //   />
            // );
          }
        } else {
          //console.log("OK !", value.message);
          // Modal.create(
          //   <Information
          //     title={Labels.registedSuccessTitle}
          //     text={value.message}
          //     onClick={() => Modal.close()}
          //   />
          // );
          await this.callGetItemsApi();
        }
      } else {
        //console.log("ERROR", value.message);
        // Modal.create(
        //   <Information
        //     title={Labels.registedErrorTitle}
        //     text={Labels.genericErrorMessage}
        //     onClick={() => Modal.close()}
        //   />
        // );
      }
    }
  }

  callGetItemsApi = async () => {
      try {
        const value = await getItemList(this.cookies.get("sessionToken"));
        if (value && !value.code && value.items && value.items?.length > 0) {
          LocalData.equipmentsData = value.items;
          await this.handleItemSelected(value.items[0].id);
        } else {
          //console.log("ERROR or no items", value);
        }
      } catch (error) {
        console.error("Failed to fetch item list", error);
      }
  };
  
  handleScheduleConfirmation = async () => {

    //1. If tempEquipData is set and no equip Selected, add it to the user
    if (this.state.scheduleData.tempEquipData && !this.state.scheduleData.itemDetails) {
      //console.log("Adding tempEquipData to user", this.state.scheduleData.tempEquipData);
      await this.addItem(this.state.scheduleData.tempEquipData);
    }

    //console.log("this.state.scheduleData", this.state.scheduleData);
    //console.log("this.state.scheduleData.schedulerId", this.state.scheduleData.schedulerId);
    const { scheduleData } = this.state;
    this.setState({ isDisabledScheduleConfirmation: true });

    (async () => {
      if (!scheduleData.schedulerId) {
        await this.createDraftSchedule();
        await new Promise(resolve => setTimeout(resolve, 500)); // wait for 500ms
        this.setState({ isDisabledScheduleConfirmation: false });
      }
      try {
        const response = await ScheduleService.createSchedule(scheduleData);
        if (response?.success) {
          this.updateClientData();
          CompanyService.getCompaniesCount();
          if (response.paymentsRequired) {
            if (response.appointmentId) {
              this.props.history.push(`/payment/${response.appointmentId}`);
            }
            else {
              const serviceId = "S-" + this.state.scheduleData.schedulerId;
              this.props.history.push(`/payment/${serviceId}`);
            }
          } else {
            this.setState({ scheduleConfirmed: true });
          }
          setTimeout(() => {
            this.setState({ isDisabledScheduleConfirmation: false });
          }, 100);
        } else {
          if (!response?.code) {
            response.code = "home";
          }
          this.renderInfoModal(response?.title, response?.body, response?.code);
          this.setState({ isDisabledScheduleConfirmation: false });
        }
      } catch (error) {
        console.error("handleScheduleConfirmation [catch error]", error);
        this.setState({ isDisabledScheduleConfirmation: false });
      }
    })();
  };

  renderStepContent = () => {
    const {
      services,
      stepActive,
      scheduleData,
      schedulerConfig,
      considerFavoriteExpert,
      expertsBySlot,
    } = this.state;
    const hasServiceFixedDate = scheduleData?.selectedServices?.some(service => service.schedule_fixed_start_enabled);

    switch (stepActive) {
      case 1:
        return (
          <Step1Schedule
            step={1}
            services={services}
            scheduleData={scheduleData}
            considerFavoriteExpert={considerFavoriteExpert}
            onDisableFavoriteExpert={() => this.setState({ considerFavoriteExpert: false })}
            onSelectedExpert={this.handleSelectedExpert}
            onSelectedServices={this.handleSelectedServices}
            onNewObservations={this.handleNewObservations}
          />
        );
      case 2:
        return (
          <Step2Schedule
            step={2}
            preferedTimeOptions={preferedTimeOptions}
            scheduleData={scheduleData}
            schedulerConfig={schedulerConfig}
            onSelectedExpert={this.handleSelectedExpert}
            onSelectedDate={this.handleSelectedDate}
            onPreferedTime={this.handlePreferedTime}
            onSelectedTime={this.handleSelectedTime}
            onChangeDisplayedDate={this.handleChangeDisplayedDate}
            isLockedToStep2={this.state.isLockedToStep2}
          />
        );

      default:
        return (
          <Step3Schedule
            step={3}
            scheduleData={scheduleData}
            expertsBySlot={expertsBySlot}
            location={schedulerConfig?.location || LocalData.configData?.companyDetails?.name}
            onSelectedExpert={this.handleSelectedExpert}
            onNewObservations={this.handleNewObservations}
            clientDataUpdated={this.clientDataUpdated}
            hasServiceFixedDate={hasServiceFixedDate}
            isLockedToStep2={this.state.isLockedToStep2}
            isLockedToStep3={this.state.isLockedToStep3}
          />
        );
    }
  };

  handleItemSelected = async (equipId) => {
    //console.log("handleItemSelected", equipId);
    const itemDetails = await ScheduleService.fetchItemDetails(equipId);
    //console.log("itemDetails", itemDetails);
    this.setState(prevState => ({
      scheduleData: { ...prevState.scheduleData, itemDetails },
    }));    
  }

  addTempEquipment = (equipData) => {
    this.setState(prevState => ({
      scheduleData: { ...prevState.scheduleData, tempEquipData: equipData },
    }));
  }

  handleScrollContinueButton = () => {
    if (this.continueButtonRef.current) {
      const continueRect = this.continueButtonRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (
        continueRect.top <= windowHeight - 120 ||
        window.scrollY + windowHeight >= document.documentElement.scrollHeight
      ) {
        if (this.state.continueButtonVisible) {
          this.setState({ continueButtonVisible: false });
        }
      } else {
        if (!this.state.continueButtonVisible) {
          this.setState({ continueButtonVisible: true });
        }
      }
    }
  };

  scrollToContinueButton = () => {
    if (this.continueButtonRef.current) {
      const element = this.continueButtonRef.current;
      const elementBottom = element.offsetTop + element.offsetHeight + 295;
      const scrollToPosition = elementBottom - window.innerHeight;
      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  renderScrollToContinueIcon = () => {
    const { continueEnabled, continueButtonVisible } = this.state;

    if (!continueEnabled || !continueButtonVisible) return null;
    return (
      <div className="scrollContinue-button" onClick={this.scrollToContinueButton}>
        <img 
          className={"icon-container"} 
          src={GENERAL.iconSmallArrowDown} 
          alt="scrollIcon" />
      </div>
    );
  };

  renderScheduleProccess = () => {
    if (this.state.scheduleConfirmed) {
      return <Fragment />;
    }
    //console.log("this.state.scheduleData", this.state.scheduleData);
    //console.log("this.state.scheduleData.tempEquipdata", this.state.scheduleData.tempEquipData);
    //console.log("this.state.scheduleData.itemDetails", this.state.scheduleData.itemDetails);
    const { services, stepActive, scheduleData } = this.state;
    const hasServiceFixedDate = scheduleData?.selectedServices?.some(service => service.schedule_fixed_start_enabled);
    const hasPayment = scheduleData?.selectedServices?.some(service => service.schedule_payment_value);
    return (
      <Fragment>
        <ScheduleTopInformation
          //itemInfoSubtitle={scheduleData?.itemDetails?.subtitle}
          //itemInfoTitle={scheduleData?.itemDetails?.title}
          //itemInfoId={scheduleData?.itemDetails?.itemFoId}
          stepActive={stepActive}
          onStepActive={this.handleStepChanged}
          hasServiceFixedDate={hasServiceFixedDate}
          handleItemSelected={this.handleItemSelected}
          toLogin={this.LoadLoginModal}
          addTempEquipment={this.addTempEquipment}
          tempEquipData={scheduleData?.tempEquipData}
          renderInformationModal={this.renderInformationModal}
          isLockedToStep2={this.state.isLockedToStep2}
          isLockedToStep3={this.state.isLockedToStep3}
        />
        {this.renderStepContent()}
        <div ref={this.continueButtonRef}></div>
        <ScheduleCTA
          stepActive={stepActive}
          servicesAvailable={services?.length > 0}
          scheduleData={scheduleData}
          onSave={this.isLiteVersion ? this.LoadLoginModal : this.handleScheduleConfirmation}
          onStepChanged={this.handleStepChanged}
          hasPayment={hasPayment}
          isDisabledScheduleConfirmation={this.state.isDisabledScheduleConfirmation}
          isLockedToStep2={this.state.isLockedToStep2}
          isLockedToStep3={this.state.isLockedToStep3}
        />
        
      </Fragment>
    );
  };

  renderScheduleConfirmation = () => {
    if (!this.state.scheduleConfirmed) {
      return <Fragment />;
    }
    const serviceId = "S-" + this.state.scheduleData.schedulerId;

    return <ScheduleConfirmation 
      serviceId={serviceId}/>;
  };

  renderFilesSection = () => {
    if (this.state.scheduleConfirmed) {
      return <Fragment />;
    }
    const { actionCloseGallery } = this.state;
    const { files } = this.state.scheduleData;
    const state = {
      bars: 0,
      name: "Serviço agendado",
    };
    const styleClass = "mt-3 mt-md-4 schedule-page-files-container";
    return (
      <MediaFiles
        key="media-files" // Ensure the key remains stable unless you need to force a remount
        data={{ files, state }}
        styleClass={styleClass}
        onCreateAppointment={true}
        newMediaFileLoaded={this.newMediaFileLoaded}
        onDeleteMediaFile={this.deleteMediaFile}
        actionCloseGallery={actionCloseGallery}
      />
    );
  };

  closeGalleryModal = () => {
    // Update state to close the gallery modal
    this.setState({ actionCloseGallery: true });
    // Automatically reset the state after 3 seconds
    setTimeout(() => {
      this.setState({ actionCloseGallery: false });
    }, 1000);
  };

  renderInformationModal = (title, text) => {
    this.renderModal(
      <Information
        title={title}
        text={text}
        onClick={() => {
          this.refModal.current.closeModal();
        }}
      />
    );
  };

  newMediaFileLoaded = (file, thumbnail) => {
    let formData = new FormData();
    formData.append("media", file, file.name);
    if (thumbnail) {
      formData.append("thumbnail", thumbnail, file.name);
    }
    this.renderModal(
      <Loader
        message="O seu ficheiro está a ser carregado..."
        inverted={false}
        local={true}
        big={false}
      ></Loader>
    );
    (async () => {
      if (!this.state.scheduleData.schedulerId) {
        await this.createDraftSchedule();
      }
      const serviceId = "S-" + this.state.scheduleData.schedulerId;
      addMediaFile(this.cookies.get("sessionToken"), serviceId, formData).then((value) => {
        this.refModal.current.closeModal();
        if (value) {
          if (value.code) {
            this.handleValueCodeResponse(value);
          } else {
            this.renderModal(
              <Information
                title={"Concluído!"}
                text={value.message}
                onClick={() => {
                  this.refModal.current.closeModal();
                  this.getAppointmentFiles();
                }}
              />
            );
          }
        } else {
          this.renderGenericError();
        }
      });
    })();
  };

  deleteMediaFile = (id) => {
    const schedulerId = "S-" + this.state.scheduleData.schedulerId;
    this.renderModal(
      <Confirmation
        title="Atenção"
        text={Labels.confirmRemoveFile}
        confirmText="Remover"
        confirmationHandle={() => {
          this.renderModal(
            <Loader
              message="O seu ficheiro está a ser apagado..."
              inverted={false}
              local={true}
              big={false}
            ></Loader>
          );
          removeMediaFile(this.cookies.get("sessionToken"), schedulerId, id).then((value) => {
            if (value) {
              if (value.code) {
                this.renderModal(
                  <Information
                    title="Pedido Sem Sucesso"
                    text="Foi encontrado um problema durante o pedido, por favor tente mais tarde"
                    onClick={() => {
                      this.refModal.current.closeModal();
                    }}
                  />
                );
              } else {
                this.renderModal(
                  <Information
                    title={"Concluído!"}
                    text={value.message}
                    onClick={() => {
                      this.refModal.current.closeModal();
                      this.closeGalleryModal();
                      this.getAppointmentFiles();
                    }}
                  />
                );
              }
            } else {
              this.renderGenericError();
            }
          });
        }}
        declineHandle={() => this.refModal.current.closeModal()}
      />
    );
  };

  LoadLoginModal = (login = false) => {
    const stateToPersist = {
      data: this.state, // The actual state data
      timestamp: new Date().getTime(), // The current time in milliseconds
    };
    // Save state to localStorage
    localStorage.removeItem("appState");
    localStorage.setItem("appState", JSON.stringify(stateToPersist));

    const redirectAuthFallback = window.location.href;
    const redirectAuthTemp = new URL(window.location.href.replace("-lite", ""));
    //redirectAuthTemp.searchParams.set("submit", "true");
    const redirectAuth = redirectAuthTemp.toString();
    this.cookies.set("redirectAuthFallback", redirectAuthFallback, Helper.cookiesLiteConfig);
    this.cookies.set("redirectAuth", redirectAuth, Helper.cookiesLiteConfig);
    //console.log("Setting redirectAuth", redirectAuth);

    window.location.href = Helper.getLiteRegisterUrl(true, login);
  };

  closeLoginModal = () => {
    this.setState({ showLoginModal: false });
  };

  renderInfoModal = (title, message, code) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => {
          switch (code) {
            case 409:
              this.handleStepChanged(2);
              this.refModal.current.closeModal();
              break;
            case 400:
              this.refModal.current.closeModal();
              this.props.history.push({
                pathname: "/",
                search: "?openAppointment=true",
              });
              break;
            case "home":
              this.refModal.current.closeModal();
              this.props.history.push({
                pathname: "/",
                search: "?openAppointment=true",
              });
              break;
            default:
              this.refModal.current.closeModal();
              break;
          }
        }}
      />
    );
  };

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  };

  render() {
    return (
      <>
        <IAgreeBox url={window.location} />
        {(LocalData.terms_accepted || this.isLiteVersion) && (

          <React.Fragment>
            <div className="h-100 schedule-page-container container px-3 pb-5">
              {this.state.showLoginModal && <LoginModal onClose={this.closeLoginModal} />}
              {this.renderScheduleProccess()}
              {this.renderFilesSection()}
              {this.renderScrollToContinueIcon()}
              {this.renderScheduleConfirmation()}
              {/* {DebuggerComponent(this.state.scheduleData)} */}
            </div>
            <Modal ref={this.refModal} />
          </React.Fragment>
        )}
      </>
    );
  }
}

export default withRouter(SchedulePage);

export const DebuggerComponent = (data) => {
  return (
    <div
      className="mt-2 d-flex justify-content-start text-start w-100"
      style={{ fontSize: "13px" }}
    >
      <pre>Informação do agendamento: {JSON.stringify(data, null, 2)}</pre>
    </div>
  );
}