import React from "react";
import Labels from "../../variables/labels";
import Button from '../button/button.jsx';
import { Rating } from "react-simple-star-rating";
import Emitter from "../../core/services.js";

class FeedbackBoxCard extends React.Component {
  constructor(props) {
    super(props);
    this.refInput = React.createRef();
    const { data: { valueRating, observation, id }, type } = this.props;
    this.state = {
      observation,
      valueRating,
      id,
      dimension: type,
      isSmall: type === 'small',
      isMedium: type === 'medium',
      isShinning: false,
    };
  }

  componentDidMount() {
    Emitter.on('RATING_SHINNING', () => {
      setTimeout(() => {      
        this.setState({ isShinning: true });
      }, 500);
      setTimeout(() => {
        this.setState({ isShinning: false });
      }, 2500);
    });
  }

  componentWillUnmount() {
    Emitter.off('RATING_SHINNING');
  }

  handleObsChange = (event) => {
    this.setState({ observation: event.target.value });
    const feedback = {
      classification: this.state.valueRating,
      observation: event.target.value, 
    }
    this.props.callback(feedback);
  }


  handleRatingValue = (value) => {
    this.setState({ valueRating: value });
    const feedback = {
      classification: value,
      observation: this.refInput.current.value, 
    }
    this.props.callback(feedback);
  }

  validateSubmition = () => {
    const feedback = {
      classification: this.state.valueRating,
      observation: this.state.observation, 
    }
    this.props.callback(feedback, true);
  }

  renderRatingContainer = (rating) => {
    const {isSmall, isMedium, isShinning} = this.state;
    const starDimension = isSmall ? '18' : isMedium ? '27' : '40';
    return(
      <div className={`rating-container ${isMedium ? 'mt-1' : 'mt-0'} ${isShinning ? 'shinning' : ''}`}>
        <div className="rate-service">
          <Rating
            onClick={this.handleRatingValue}
            transition
            initialValue={rating}
            fillIcon={<svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={starDimension}
                height={starDimension}
                style={{marginLeft: "12px"}}
              >
              <g fill="none" fillRule="evenodd">
                  <path fill="none" d="M0 0h16v16H0z"/>
                  <path d="m8 12-4.114 2.163.785-4.581-3.328-3.245 4.6-.669L8 1.5l2.057 4.168 4.6.669-3.328 3.245.785 4.581z" stroke="#0196E0" fill="#0196E0" strokeLinejoin="round"/>
              </g>
              </svg>
            }
            emptyIcon={<svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={starDimension}
                height={starDimension}
                style={{marginLeft: "12px"}}
              >
              <g fill="none" fillRule="evenodd">
                  <path fill="none" d="M0 0h16v16H0z"/>
                  <path d="m8 12-4.114 2.163.785-4.581-3.328-3.245 4.6-.669L8 1.5l2.057 4.168 4.6.669-3.328 3.245.785 4.581z" stroke="#0196E0" strokeLinejoin="round"/>
              </g>
              </svg>
            }
          />
        </div>
      </div>
    );
  }

  renderSmallButton = (isSmall) => {
    if (!isSmall) {
      return <></>;
    }

    return (
      <div className="ms-2">
        <Button
          className="cta-button-secondary white d-medium"
          type="button"
          value="Enviar"
          onClick={() => this.validateSubmition()}
        />
      </div>
    );
  }

  render() {
    const { feedback, valueRating, observation, id, isSmall, dimension } = this.state;
    const rows = isSmall ? 1 : 4;
    const renderFeedbackTitle = () => {
      const titleText = () =>{
        if (feedback) { 
          return <span> { Labels.feedbackTitle } </span>
        } else {
          return <span> {Labels.feedbackMessage } </span>
        }
      }
      return !isSmall? null : (<p className="card-service-feedback-text m-0 mt-1 ">{titleText()}</p>);
    }
   
    return (
      <div className={`card-service-feedback-container ${!isSmall ? 'no-card w-100' : ''}`}>
        {this.renderRatingContainer(valueRating, id)}
        { renderFeedbackTitle(feedback) }
        <div className={`text-area-container ${isSmall ? 'pt-2' : 'mt-3'}`}>
          <textarea ref={this.refInput} defaultValue={observation} maxLength="2000" onChange={this.handleObsChange}
            rows={rows} className={`text-area-feedback ${dimension}`} placeholder='Observações' />
          {this.renderSmallButton(isSmall)}
        </div>
      </div>
    );
  }
}
export default FeedbackBoxCard;