import React, { Component } from "react";
import Labels from "../../../variables/labels";
import Helper from "../../../core/helper/helper";
import SelectList from "../../../components/SelectComponent/selectList/select-list";
import LocalData from "../../../core/localData";
import InputDatePicker from "../../../components/datePicker/datePicker";


class ScheduleAddEquipment extends Component {
  constructor(props) {
    super(props);
    this.initialFields = Helper.itemFieldsToAdd();
    this.isLiteVersion = Helper.isLiteVersion();
    this.state = {
      fields: props.defaultItem,
      inputSelectFields: null,
      selectedOption: null,
      disableButton: !Helper.hasValidFields(this.initialFields),
      iAgree: LocalData.terms_accepted,
    }
    this.baseState = {...this.state};
    this.datePickerRef = React.createRef();

    //console.log("props.defaultItem", props.defaultItem);
  }


  componentDidMount() {
    this.setState({
      fields: this.props.defaultItem || this.initialFields,
      inputSelectFields: Helper.buildInputSelectFields(),
    });
  }

  resetState = () => {
    //console.log("Resseting Fields");
    this.setState(this.baseState);
    this.setState({
      fields: this.initialFields,
      inputSelectFields: Helper.buildInputSelectFields(),
    });
    //console.log("Fields reseted", this.initialFields);
    this.props.onChange(this.initialFields);

  }

  updateSelectedOption = (field, option) => {
    const { fields } = this.state;   
    const attributes = {...field, selectedValue: option};
    this.updateEquipmentState(fields, field, attributes);
  }

  updateField = (field, event) => {
    const { fields } = this.state;
    const attributes = {...field, selectedValue: event?.target?.value};
    this.updateEquipmentState(fields, field, attributes);
  }

  updateDateField = (field, date) => {
    if (!date) {
      return;
    }
    const { fields } = this.state;
    const attributes = {...field, selectedValue: date};
    this.updateEquipmentState(fields, field, attributes);
  }

  updateEquipmentState = (newFields, field, attributes) => {
    const index = Helper.findFieldIndex(newFields, field);
    const updatedFields = [
      ...newFields.slice(0, index),
      Object.assign({}, newFields[index], attributes),
      ...newFields.slice(index + 1)
    ];
     this.setState({
       fields: updatedFields,
       disableButton: !Helper.hasValidFields(updatedFields),
     });
     this.props.onChange(updatedFields);
  }

  renderDateInput = (field) => {
    const { value, label, name, type } = field;

    return (
      <div className='input-container mb-2 mt-0' key={name}>
        <p className="input-label invert regular"> { label } </p>
        <div className="input-row-container">
          <InputDatePicker
            key={name}
            type={type}
            id={name}
            name={label}
            value={value}
            onChange={(date) => this.updateDateField(field, date)}
          />
        </div>
      </div>
    );
  }

  renderInputSelect = (field) => {
    const { inputSelectFields } = this.state;

    const renderWarning = (field) => {
      if (Helper.isSelectFieldEmpty(field)) {
        return (<input required onInvalid={this.handleInputError} className="required-warning-tooltip"/> );
      }
      return;
    }

    return (
      <React.Fragment key={field.name}>
        <div className="select-container mb-2" style={{position: 'relative'}} >
          { renderWarning(field) }
          <SelectList  options={inputSelectFields ? inputSelectFields[0] : null}
                      placeholder={`${field.label}`} 
                      selectedOption={field.selectedValue} 
                      //required={field.required}
                      onChange={(option) => this.updateSelectedOption(field, option)}
                      portal={true}
                      notSearchable={true}
                      placeHolderStyle={{fontSize: '16px', color: 'rgb(165, 173, 189)', fontFamily: "SourceSansPro-Regular"}}
                      controlStyle={{minHeight: '26px', maxHeight: '26px', border: 'none'}}
          />
        </div>
      </React.Fragment>
    );
  }

  handleInputError = (e) => {
    if (e) {
      e.target.setCustomValidity('');
      if (!e.target.validity.valid) {
        e.target.setCustomValidity(Labels.requiredField);
      }
    }
  }

  renderItemFields = () => {
    const { isForm } = this.props;
    const { fields } = this.state;

    if (!fields) {
      return;
    }
    return fields.map((field) => {
      if (field.type === 'inputselect') {
        return this.renderInputSelect(field);
      }
      if (field.type === 'birthday' || field.type === 'date') {
        return this.renderDateInput(field);
      }
      if (!field.required && Helper.isAuto()) {
        return null;
      }
      const inputStyle = `input-medium-default schedule ${field.required ? '' : ''} ${!isForm ? 'register' : '' }`;
      //console.log("Field", field);
      //console.log("Field.selectedValue", field.selectedValue);
      return (
        <div className='input-container mb-2 mt-0' key={field.name}>
          <div className="input-row-container">
            <input
              className={inputStyle}
              type='text'
              required={field.required}
              onInvalid={this.handleInputError}
              onChange={(value) => this.updateField(field, value)}
              value={field.selectedValue || ""} // controlled input value
              autoFocus
              placeholder={field.label}
            />
          </div>
        </div>
      )
    });
  };

  handleSubmit = (event) => {
    const { fields } = this.state;
    event.preventDefault();

    if (Helper.hasValidFields(fields)) {
      this.resetState();
      this.props.onClick(fields);
    }
  }

  render() {
    return (
      <React.Fragment>
        { this.renderItemFields() }
      </React.Fragment>
    );
  }
}
export default  ScheduleAddEquipment;