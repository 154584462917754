// React Imports
import React from 'react';
import { withRouter } from "react-router-dom";
//import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';

// Components Imports
import InputField from 'components/inputField/inputField.jsx';
import Button from 'components/button/button.jsx';
import Modal, { Information } from 'components/modal/modal.jsx';
// import jwt_decode from "jwt-decode";
// API Imports
import { authenticateUser, sendNewEmailConfirmation } from 'api/routes/api.route.js';
import { preUserSignUp } from 'api/routes/api.route.js';
import Labels from '../../variables/labels';
import Helper from '../../core/helper/helper';
import LocalData from '../../core/localData';
import LinkRecoveryPassword from '../../components/button/LinkRecoveryPassword';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    Helper.callLogError('401 Loaded /login '+ this.cookies.get("sessionToken") + ' ');
    Helper.logout();
    //    ReactGA.pageview(window.location.pathname + window.location.search);
    const search = this.props.location.search;
    let email = new URLSearchParams(search).get('email') ? decodeURIComponent(new URLSearchParams(search).get('email')) : '';
    email = this.props.location?.state?.email ? decodeURIComponent(this.props.location?.state?.email) : email;
    this.setState({ email });
  }
  /**
  * Extrai o valor do campo do email
  *
  * @param {event} event - callback do evento OnChange()
  */
  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  }
  /**
   * Extrai o valor do campo da password
   * 
   * @param {event} event - callback do evento OnChange()
  */
  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  }
  /**
   *  Função OnClick do formulário de Login, que irá fazer o pedido a API 
   *  para que caso o user exista, o mesmo é reencaminahdo para a sua 
   *  dashboard
   * 
   *  @param {event} event - callback do evento OnSubmit()
  */
  handleSubmit = (event) => {
    event.preventDefault();

    const userAuth = {
      email: this.state.email,
      password: this.state.password,
    }

    authenticateUser(userAuth).then(response => {
      if (response) {
        if (response.http_code) {
          if (response.http_code === 401) {
            return;
          } else if (response.http_code === 400) {
            this.renderInfoModal('Dados Incorrectos', response.message);
          } else if (response.http_code === 403) {
            this.renderModal(
              <Information
                title={Labels.genericErrorTitle}
                text={response.message || Labels.authenticateUserError403Text}
                register={true}
                email={this.state.email}
                onClick={() => {
                  this.refModal.current.closeModal();
                }} />
            );
            this.refModal.current.openModal();
          } else if (response.http_code === 409) {
            if (response.code === 2) {
              LocalData.userRegistered = false;
              this.preSignup(userAuth);
            }
            if (response.code === 1) {
              this.renderInfoModal('Registo Incompleto', response.message);
            }
          } else if (response.http_code === 404) {
            if (response.code === 3) {
              this.renderInfoModal(Labels.genericErrorTitle, response.message);
            }
          } else {
            this.renderGenericError();
          }
        } else {
          const eventInfo = {
            auth_token: response.data?.token,
            route: this.cookies.get('route'),
            client_route: this.cookies.get('clientroute')
          };
          if (Helper.isIOSWebview()) {
            try {
              window.webkit.messageHandlers.login.postMessage(eventInfo);
            } catch (error) {
              console.error(error);
            }
          }
          if (Helper.isAndroidWebview()) {
            try {
              window.Android.login(eventInfo.auth_token, eventInfo.route, eventInfo.client_route);
            } catch (error) {
              console.error(error);
            }
          }
          this.cookies.set('sessionToken', response.data?.token, Helper.cookiesConfig);
          LocalData.userRegistered = true;
          this.props.history.push(`/services`);
        }
      } else {
        this.renderGenericError();
      }
    });
  }

  preSignup = (userAuth) => {
    let body = { ...userAuth };
    if (this.cookies.get('userId')) {
      body = {
        ...body,
        user_id: this.cookies.get('userId'),
        work_token: this.cookies.get('liteToken')
      };
    }
    preUserSignUp(body).then(response => {
      if (response) {
        if (response.code) {
          if (response.code === 409) {
            this.renderInfoModal('E-mail já Associado', response.message);
          } else if (response.code === 404) {
            if (response.error_code === 3) {
              this.renderInfoModal(Labels.genericErrorTitle, response.message);
            }
          } else {
            this.renderGenericError();
          }
        } else {
          if (response.type === 'BasicClientDetails') {
            if (response.user_id) {
              this.cookies.set('userId', response.user_id, Helper.cookiesLiteConfig);
            }
            if (response.work_token) {
              this.cookies.set('liteToken', response.work_token, Helper.cookiesLiteConfig);
            }
            LocalData.userData = response;
            LocalData.userRegistered = false;
            this.props.history.push('/dados-cliente');
          } else {
            this.cookies.set('sessionToken', response.token, Helper.cookiesConfig);
            this.cookies.remove('userId', Helper.cookiesLiteConfig);
            this.cookies.remove('liteToken', Helper.cookiesLiteConfig);
            LocalData.userData = response;
            LocalData.userRegistered = true;
            window.location.href = `${process.env.PUBLIC_URL}/services`;
          }
        }
      } else {
        this.renderGenericError();
      }
    });
  }

  /**
   *  Pedido para reenviar um email de confirmação de registo
  */
  sendNewEmail = (USER_PATH) => {
    let body = { ...USER_PATH };
    if (this.cookies.get('userId')) {
      body = {
        ...body,
        user_id: this.cookies.get('userId'),
        work_token: this.cookies.get('liteToken')
      };
    }
    sendNewEmailConfirmation(body).then(res => {
      if (res) {
        if (res.http_code) {
          if (res.http_code === 400) {
            this.renderInfoModal('Dados Incorrectos', res.message);
          } else {
            this.renderGenericError();
          }
        } else {
          this.renderInfoModal('E-mail Reenviado', res.message);
        }
      } else {
        this.renderGenericError();
      }
    });
  }

  handleClickRegister = () => {
    this.props.history.push({
      pathname: '/registo',
      state: { email: this.state.email }
    });
  }

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()} />
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  render() {
    return (
      <React.Fragment>
        <h3 className="text-center w-100 mb-4"> {Labels.login.header}</h3>
        <div className="grey-box-container">
          <div className="box-form-container">
            <form onSubmit={this.handleSubmit}>
              <InputField
                styleInput="input-medium-default"
                type="email"
                title="Utilizador"
                pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                value={this.state.email}
                placeholder="exemplo@mail.com"
                onChange={this.handleChangeEmail}
                required={true}
              />
              <InputField
                styleInput="input-medium-default password"
                type="password"
                title="Password"
                value={this.state.password}
                onChange={this.handleChangePassword}
                required={true}
              />
              <LinkRecoveryPassword email={this.state.email} />
              <Button
                className="cta-button-primary d-medium w-100 mt-4"
                type="submit"
                value="Entrar"
              />
            </form>
          </div>
        </div>

        {/* <ButtonDivider /> */}
        {/* <LoginProvider provider="google" /> */}
        {/* <LoginProvider provider="apple" /> */}

        <div className="link-container mt-4" onClick={() => this.handleClickRegister()}>
          {Labels.login.linkMessage}
        </div>
        <Modal ref={this.refModal} />
      </React.Fragment>
    );
  }
}

export default withRouter(Login);