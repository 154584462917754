import React, { useState, useEffect } from "react";
import { HelperSchedule } from "../../core/helpers/helperSchedule";
import CardItemInformation from "../../../../components/card/equipment/CardItemInformation";
import { GENERAL, SERVICE } from "../../../../assets/images/images";
import Helper from "../../../../core/helper/helper";
import CheckOutDate from "../../../../components/card/service/checkOutDate";
import CheckInDate from "../../../../components/card/service/checkInDate";
import ScheduleService from "../../core/services/schedule.service";

/**
 * @param {Object} props
 * @param {string} props.location
 * @param {ScheduleData} props.scheduleData
 */
const ScheduleServiceDetailsCard = (props) => {
  const [location, setLocation] = useState(props.location);
  const [scheduleData, setScheduleData] = useState(props.scheduleData);
  const [date, setDate] = useState(props.scheduleData?.selectedDate ? props.isPayment ? props.scheduleData?.selectedDate : props.scheduleData?.selectedDate?.toLocaleDateString('pt-PT') : undefined);
  const [dateEnd, setDateEnd] = useState(props.scheduleData?.dateEnd ? props.isPayment ? props.scheduleData?.dateEnd : props.scheduleData?.dateEnd?.toLocaleDateString('pt-PT') : undefined);
  const isInternalLiteEquip = typeof scheduleData?.itemDetails?.itemFoId === 'string' && scheduleData?.itemDetails?.itemFoId.includes("int");
  const [hasServiceFixedDate, setHasServiceFixedDate] = useState(props.hasServiceFixedDate);
  const [extractedFixedDate, setExtractedFixedDate] = useState(null);
  const [extractedFixedEndDate, setExtractedFixedEndDate] = useState(null);
  const [isPayment] = useState(props.isPayment);

  useEffect(() => {
    setHasServiceFixedDate(props.hasServiceFixedDate);
    if (props.hasServiceFixedDate) {
      const extractedFixedDate = scheduleData?.selectedServices?.find(
            (service) => service.schedule_fixed_start_enabled
          )?.schedule_fixed_start || null;
      const extractedFixedEndDate = scheduleData?.selectedServices?.find(
            (service) => service.schedule_fixed_start_enabled
          )?.schedule_fixed_end || null;
      setExtractedFixedDate(extractedFixedDate);
      setExtractedFixedEndDate(extractedFixedEndDate);
    }
  }, [props.hasServiceFixedDate, scheduleData.selectedServices]);

  useEffect(() => {
    setScheduleData(props.scheduleData);
    if (props.scheduleData?.selectedDate) {
      setDate(props.isPayment ? props.scheduleData?.selectedDate : props.scheduleData?.selectedDate?.toLocaleDateString('pt-PT'));
    }
    if (props.scheduleData?.dateEnd) {
      setDateEnd(props.isPayment ? props.scheduleData?.dateEnd : props.scheduleData?.dateEnd?.toLocaleDateString('pt-PT'));
    }
  }, [props.scheduleData, props.isPayment]);

  useEffect(() => {
    setLocation(props.location);
  }, [props.location]);

  const renderItemImage = () => {
    const imgSrc = Helper.isLiteVersion() ? (scheduleData?.itemDetails?.image ? scheduleData?.itemDetails?.image : '') : HelperSchedule.urlItemImage(scheduleData?.itemDetails?.photo);
    return (
      <div className="card-service-image-container details">
        <img
          className={!isInternalLiteEquip && Helper.isLiteVersion() ? 'pixelized' : ''}
//          src={HelperSchedule.urlItemImage(scheduleData?.itemDetails?.photo)}
          src={imgSrc}
          alt="service_image"
          onError={(e) => ((e.target).src = Helper.defaultImage())}
        />
      </div>
    );
  };

  const renderItemInformation = () => {
    const { itemDetails } = scheduleData;
    const cardItemData = { title: itemDetails?.title, subtitle: itemDetails?.subtitle, id: itemDetails?.itemFoId };
    return <CardItemInformation data={cardItemData} styleClass="mt-1 mt-md-0 text-start details" origin="ScheduleService"/>
  }

  const getFixedDateInfo = (context) => {
    if (!extractedFixedDate) return { formattedDate: null, formattedTime: null };
  
    const fixedDateObject = context === "end" ? extractedFixedEndDate : extractedFixedDate;
    return {
      formattedDate: ScheduleService.convertDateTimeToPTFormat(fixedDateObject, true),
      formattedTime: HelperSchedule.formatFixedTimeScheduler(fixedDateObject),
    };
  };
  
  const getDisplayDate = (type, date, hasServiceFixedDate, fixedDateInfo) => {
    if (type === "date") {
      if (isPayment) {
        return date;
      }
      return hasServiceFixedDate
        ? fixedDateInfo.formattedDate
        : HelperSchedule.formatDateScheduler(date);
    }
    return hasServiceFixedDate ? fixedDateInfo.formattedTime : scheduleData?.selectedTime?.value || "-";
  };
  
  const renderDate = (type, context = null) => {
    const fixedDateInfo = getFixedDateInfo(context);
  
    return (
      <div className="service-equipment-data-row d-inline-flex text-start w-50">
        <img
          src={
            type === "date"
              ? context === "end"
                ? SERVICE.checkout
                : GENERAL.iconCalendarScheduled
              : GENERAL.iconClock
          }
          alt="scheduled-date"
        />

        <div className={"service-date-text flex-start"}>
          {getDisplayDate(
            type,
            context === "start" ? date : dateEnd,
            hasServiceFixedDate,
            fixedDateInfo
          )}
        </div>
      </div>
    );
  };

  const renderCheckinDate = () => {
    return (
      <CheckInDate
        data={{
          bars: scheduleData?.state?.bars,
          dateStart: date,
          dateStartState: Helper.printDate(date),
        }}
      />
    );
  }

  const renderCheckoutDate = () => {
    return (
      <CheckOutDate
        data={{ bars: scheduleData?.state?.bars, dateEnd, dateEndState: Helper.printDate(dateEnd) }}
      />
    );
  }

  const renderCheckInOutDates = () => {
    return (
      <div className='service-equipment-data d-inline-flex w-100 mt-4'>
        {renderCheckinDate()}
        {renderCheckoutDate()}
      </div>
    );
  }

  const renderDates = () => {
    return (
      <div className='service-equipment-data d-inline-flex w-100 mt-4'>
        {renderDate('date', "start")}
        {renderDate(hasServiceFixedDate ? "date" : 'time', hasServiceFixedDate ? "end" : null)}
      </div>
    );
  };

  const renderLocationContainer = () => {
    return (
      <div className="d-flex justify-content-center justify-content-sm-start mt-3">
        <div className="card-service-location" title={'Localização'}>
          <img src={GENERAL.iconLocation} alt="" />
          <div className="ms-2 card-service-location-text">
            {Helper.isDemo(location) ? "Empresa Demo" : location}
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={`card-service-main-container p-3 w-100 m-0 h-auto mw-100`}>
        <div className="row">
          <div className="col-12 col-sm-6">{renderItemImage()}</div>
          <div className="col-12 col-sm-6">
            {renderItemInformation()}
            {(!isPayment || props.isAppointment) && renderDates()}
            {isPayment && !props.isAppointment && renderCheckInOutDates()}
            {renderLocationContainer()} 
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScheduleServiceDetailsCard;
