// React Imports
import React from 'react';
//import ReactGA from 'react-ga';
import Emitter from 'core/services.js';
import Cookies from 'universal-cookie';
import Labels from '../../variables/labels';
import LocalData from '../../core/localData';
import { withRouter, Prompt } from 'react-router-dom';
import Helper from '../../core/helper/helper';
import Button from 'components/button/button.jsx';
import { getClientData, updateClientData } from '../../api/routes/api.route';
import InputField from 'components/inputField/inputField.jsx';
import PasswordField from '../../components/inputField/PasswordField.jsx';
import Modal, { Confirmation, Information } from 'components/modal/modal.jsx';
import { userSignUp, requestPhoneCodeToValidate, requestEmailCodeToValidate, validateUserPhone, validateUserEmail, registerNewClient, getCountriesListData, 
          getEstablishmentTerms } from '../../api/routes/api.route.js';
import CompanyService from '../../core/services/company.service';
import SelectList from '../../components/SelectComponent/selectList/select-list';
import IAgreeBox from '../../components/iagree/iagreeBox.jsx';

class DadosCliente extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.refModal = React.createRef();
    this.refContactList = React.createRef();
    this.refEmailList = React.createRef();
    this.refName = React.createRef();
    this.refAdress = React.createRef();
    this.refZip = React.createRef();
    this.refCity = React.createRef();
    this.refNif = React.createRef();
    this.isProfilePage = this.props.location.pathname.toString().includes('perfil');
    this.abortController = new AbortController();
    this.state = {
      submitLabel: 'Concluído',
      userData: {},
      countriesList: null,
      selectedCountry: null/* {id: '175', country: 'Portugal'} */,
      acceptedTerms: false,
      termsAndConditions: Helper.getTermsAndConditions(),
      shouldBlockNavigation: false,
      disableNIF: false,
      loginPhone: LocalData.userData?.login_phone || '',
      loginPhoneValidated: LocalData.userData?.login_phone_validated || '',
      loginEmail: LocalData.userData?.login_email || '',
      loginEmailValidated: LocalData.userData?.login_email_validated || '',
      inputEmailValue: LocalData.userData?.login_email || '',
      inputEmailCode: '',
      inputPhoneValue: LocalData.userData?.login_phone || '',
      inputPhoneCode: '',
      emailCode: '',
      phoneCode: '',
      phoneCodeRequested: false,
      phoneCodeRequestSuccess: '',
      emailCodeRequested: false,
      emailCodeRequestSuccess: '',
      password: '',
      checkPassword: '',
      validateNowPassword: false,
    };
  }

  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  phoneRegex = /(^(003519\d{8})$)|(^\+3519\d{8}$)|(^(?!00|0|\+)[9]\d{8}$)/;
  phoneCodeRegex = /(^(?!\+|00)\d{6,}$)/;
  emailCodeRegex = /(^(?!\+|00)\d{6,}$)/;

  componentDidMount() {
//    ReactGA.pageview(window.location.pathname + window.location.search);
    if (!LocalData.terms_accepted) return;
    
    if (!LocalData.userRegistered) {
      this.getTermsAndConditions();
      this.getUserData();
    } else if (this.isProfilePage && LocalData.terms_accepted) {
      this.getClient();
    }
    this.getCountriesList();
    Emitter.on('LIST_SELECT_ELEMENT_CHANGED', () => this.updateSubmitLabel(null));

    if (Helper.isAndroidWebview()) {
      window.receiveOTP = (otp) => {
        this.setPhoneCodeDirectly(otp);
      };
    }
  }

  componentWillUnmount() {
    Emitter.off('LIST_SELECT_ELEMENT_CHANGED');
    if (this.timerCleanup) {
      this.timerCleanup();
    }
    if (Helper.isAndroidWebview()) {
      delete window.receiveOTP;
    }
  }

  receiveOtpBrowser = async () => {
    const options = {
      otp: { transport: ['sms'] },
      signal: this.abortController.signal,
    };

    if ('OTPCredential' in window) {
      try {
        const content = await navigator.credentials.get(options);
        this.setPhoneCodeDirectly(content.code);
      } catch (error) {
        console.error(error);
      }
    }
  };

  handleEmailChange = (event) => {
    event.stopPropagation();
    const input = event.target.value;
    this.setState({ inputEmailValue: input });

    if (this.emailRegex.test(input)) {
      this.setState({ loginEmail: input });
      LocalData.userData.login_email = input;
    } else {
      this.setState({ loginEmail: '' });
    }
  };
  

  /**
  * Pedido para obter Termos e Condições
  */
  getTermsAndConditions() {
    getEstablishmentTerms().then(value => {
      if (value) {
        if (!value.code) {
          this.setState({ termsAndConditions: value.terms });
        }
      }
    });
  }

  handleChangePhone = (e) => {
    e.stopPropagation();
    const input = e.target.value;
    this.setState({ inputPhoneValue: input });
  
    if (this.phoneRegex.test(input)) {
      this.setState({ loginPhone: input });
      LocalData.userData.login_phone = input;
    } else {
      this.setState({ loginPhone: '' });
    }
  };
  
  handleChangePassword = (value) => {
    this.setState({ password: value });
  };
  
  /**
   * Pedido para obter lista de países
  */
  getCountriesList() {
    getCountriesListData().then(value => {
      if (value) {
        if (value.code) {
          console.error(value);
        } else {
          if (value.countries) {
            const countries = value.countries.map((el) => {
              return { value: el.id, label: el.country };
            });
            this.setState({ countriesList: countries });
          }
        }
      }
    });
  }

 /**
 * Pedido para obter dos dados do cliente
 */
  getUserData() {
    const userData = LocalData.userData;
    if (userData) {
      this.refName.current.setValue(userData.name);
      this.refAdress.current.setValue(userData.address);
      this.refZip.current.setValue(userData.postal_code);
      this.refCity.current.setValue(userData.city);
      this.refNif.current.setValue(userData.vat);
      const emailsList = this.buildEmailsList(userData);
      this.refEmailList.current.addArrayToList(emailsList, 'email');
      this.setState({ selectedCountry: userData.country });
      if (userData.phones) {
        this.refContactList.current.addArrayToList(userData.phones, 'number');
      }

      this.setState({
        loginPhone: userData?.login_phone  || '',
        inputPhoneValue: userData?.login_phone || '',
        loginPhoneValidated: userData?.login_phone_validated || false ,
        loginEmail: userData?.login_email || '',
        inputEmailValue: userData?.login_email || '',
        loginEmailValidated: userData?.login_email_validated || false ,
      });
      

      LocalData.userData = userData;
      this.setState({ userData });
      this.forceUpdate();
    }
  }

   /**
  * Pedido para obter dos dados do cliente da API
  */
    getClient = () => {
      getClientData(this.cookies.get('sessionToken')).then(value => {
        if (value) {

          if (value.code) {
            Modal.create(<Information
              title='Pedido Sem Sucesso'
              text={value.message}
              onClick={() => {
                this.props.history.push(`/services`)
              }} />);
          } else {
            this.refName.current.setValue(value.name);
            this.refAdress.current.setValue(value.address);
            this.refZip.current.setValue(value.postal_code);
            this.refCity.current.setValue(value.city);
            this.refNif.current.setValue(value.vat);
            this.refContactList.current.addArrayToList(value.phones, "number");
            const emailsList = this.buildEmailsList(value);
            this.refEmailList.current.addArrayToList(emailsList, 'email');
            this.setState({
              selectedCountry: value.country,
              email: value.email,
              secondaryEmail: value.secondary_email,
              disableNIF: value.vat && value.vat !== '',

              loginPhone: value.login_phone || '',
              inputPhoneValue: value.login_phone || '',
              loginPhoneValidated: value.login_phone_validated || false ,
              loginEmail: value.login_email || '',
              inputEmailValue: value.login_email || '',
              loginEmailValidated: value.login_email_validated || false
            });
            LocalData.userData = value;
          }
        } else {
          Modal.create(<Information
            title='Pedido Sem Sucesso'
            text={'Foi encontrado um problema durante o pedido, por favor tente mais tarde'}
            onClick={() => {
              Modal.close();
            }} />);
        }
      });
    }

  /**
   * Apresentar os termos e condições
   */
  showTermsAndConditions = () => {
    this.renderInfoModal(Labels.termsConditionsTitle, this.state.termsAndConditions);
  }

  /**
   *  Registar Session Token e Redirecionar para a area de serviço
   * 
   *  @param {string} token - session token value
  */
  confirmAutoLogin = (value) => {
    this.cookies.set('sessionToken', value.token, Helper.cookiesConfig);
    LocalData.userRegistered = true;
    this.refModal.current.closeModal();
    CompanyService.getCompanyList();
    this.props.history.push(`/`);
  }

  /**
   *  Verifica se o token recebido é válido.
  */
  registrationTokenValidation = () => {
    return !!this.state.userData?.registration_token;
  }

  /**
   *  Verifica se a informação dos custom forms é válida antes de a mesma ser
   *  enviada.
  */
  customFormValidation = () => {

    // const nifResult = /^\d+$/.test(this.refNif.current.getValues());
    const zipValue = this.refZip.current.getValues();
    const zipRegex = /^\d{4}-\d{3}$/;
    const { selectedCountry } = this.state;
    const nif = this.refNif.current.getValues();
    if (nif && selectedCountry && selectedCountry.country === 'Portugal') {
      if (!Helper.checkVatNumber(nif)) {
        Modal.create(<Information title="Aviso" text={Labels.profileInvalidNIF} onClick={() => Modal.close()} />);
        return false;
      }
    }

    if (!LocalData.userRegistered) {
      if (!this.state.acceptedTerms) {
        Modal.create(<Information title="Aviso" text={Labels.readTermsConditionsError} onClick={() => Modal.close()} />);
        return false;
      }

      if (!this.registrationTokenValidation()) {
        return false;
      }
    }

    if (selectedCountry?.country === 'Portugal' && !zipRegex.test(zipValue)){
      Modal.create(<Information title="Aviso" text={Labels.invalidZipCode} onClick={() => Modal.close()} />);
      return false;
    }

    return true;
  }

  getPrimaryEmail = () => {
    return this.refEmailList.current.listValues()[0] ? this.refEmailList.current.listValues()[0]['email'] : null;
  }

  getSecondaryEmail = () => {
    return this.refEmailList.current.listValues()[1] ? this.refEmailList.current.listValues()[1]['email'] : null;
  }

  getPrimaryInvoice = () => {
    return this.refEmailList.current.listValues()[0] ? this.refEmailList.current.listValues()[0]['invoice'] : null
  }

  getSecondaryInvoice = () => {
    return this.refEmailList.current.listValues()[1] ? this.refEmailList.current.listValues()[1]['invoice'] : null;
  }

  /**
  * build list of emails
  */
  buildEmailsList(info) {
    const list = [];
    if (info.email) { list.push({ 'email': info.email, 'primary': true, 'invoice': info.invoice_primary }) }
    if (info.secondary_email) { list.push({ 'email': info.secondary_email, 'primary': false, 'invoice': info.invoice_secondary }) }
    return list;
  }

  /**
  * Confirmação para alterações nos Dados do Cliente
  */
  confirmProfileChanges = (newUserData) => {
    this.refModal.current.renderView(
      <Confirmation
        title='Atenção'
        text='Confirma a atualização de Dados de Cliente?'
        confirmationHandle={() => {
          this.submitNewData(newUserData)
        }}
        declineHandle={() => this.refModal.current.closeModal()}
      />);
    this.refModal.current.openModal();
  }

  submitNewData(userData) {
    if (!LocalData.userRegistered) {
      const token = this.state.userData.registration_token;
      this.callRegisterNewClient(userData, token);
      return;
    }
    this.handleUpdateClient(userData);
  }

  /**
   *  Função OnClick do formulário de Registo de Dados do utilizador, que irá fazer o pedido a API 
   *  para registar os dados do utilziador na sua conta, em caso de dados incorrectos ou operação 
   *  falhada será notificado com messagem atraves de um modal.
   * 
   *  @param {event} event - callback do evento OnSubmit()
  */
  handleSubmit = (event) => {

    event.preventDefault();

    if (this.customFormValidation()) {
      const { selectedCountry} = this.state;
      const newUserData = {
        name: this.refName.current.getValues(),
        address: this.refAdress.current.getValues(),
        postal_code: this.refZip.current.getValues(),
        city: this.refCity.current.getValues(),
        country_id: selectedCountry ? +selectedCountry.id : null,
        country: selectedCountry,
        phones: [],
        email: this.getPrimaryEmail(),
        secondary_email: this.getSecondaryEmail(),
        vat: this.refNif.current.getValues(),
        invoice_primary: this.getPrimaryInvoice(),
        invoice_secondary: this.getSecondaryInvoice(),
      }

      newUserData.phones = this.refContactList.current.listValues();

      this.confirmProfileChanges(newUserData);
    }
  }

  callRegisterNewClient(newUserData, TOKEN) {
    let body = {
      ...newUserData,
      token: TOKEN
    }
    if (this.cookies.get('userId')) {
      body = {
        ...body,
        user_id: this.cookies.get('userId'),
        work_token: this.cookies.get('liteToken')
      };
    }
    registerNewClient(body).then(value => {
      if (value) {
        if (value.code) {
          if (value.code === 401) {
            Helper.callLogError('401 registerNewClient '+ this.cookies.get('userId') + ' ');
            return;
          } else if (value.code === 400) {
            this.renderInfoModal('Dados Incorrectos', value.message);
          } else if (value.code === 409) {
            this.renderInfoModal('Registo Inválido', value.message);
          } else if (value.code === 404) {
            if (value.error_code === 3) {
              this.renderInfoModal(Labels.genericErrorTitle, value.message);
            }
          } else {
            this.renderGenericError();
          }
        }
        else {
          this.cookies.remove('userId', Helper.cookiesLiteConfig);
          this.cookies.remove('liteToken', Helper.cookiesLiteConfig);
          this.renderModal(<Information title='Registo Concluído' text={value.message}
            onClick={() => this.confirmAutoLogin(value)} />);
          this.setState({ submitLabel: 'Concluído', userData: newUserData, shouldBlockNavigation: false });
        }
      }
      else {
        this.renderModal(<Information title='Pedido Sem Sucesso' text={Labels.genericErrorMessage} onClick={() => this.props.history.push('/')} />);
      }
    });
  }


  /**
  * Pedido para alteração dos dados do cliente
  *
  * @param {any} userData - objecto com a nova informação do cliente
  */
   handleUpdateClient = (userData) => {
    updateClientData(this.cookies.get('sessionToken'), userData).then(value => {
      if (value) {
        if (value.code) {
          Modal.create(<Information
            title='Pedido Sem Sucesso'
            text={value.message}
            onClick={() => Modal.close()} />);
        } else {
          LocalData.userData = userData;
          this.refModal.current.closeModal();
          Modal.create(<Information
            title='Dados Alterados'
            text={value.message}
            onClick={() => Modal.close()} />
          );
          this.setState({ userData, submitLabel: 'Concluído', shouldBlockNavigation: false });
        }
      } else {
        Modal.create(<Information
          title='Pedido Sem Sucesso'
          text={'Foi encontrado um problema durante o pedido, por favor tente mais tarde'}
          onClick={() => {
            Modal.close();
          }} />);
      }
    });
  }

  updateSubmitLabel = (e) => {
    if (e) {
      const target = e.target;
      if (target.type !== 'checkbox') {
        e.preventDefault();
      }
    }
    if (this.state.submitLabel !== 'Guardar') {
      return this.setState({ submitLabel: 'Guardar Alterações', shouldBlockNavigation: true });
    }
  }

  updateCountry = (option) => {
    const country = option ? { id: option.value, country: option.label } : null;
    this.setState({ selectedCountry: country });
  }

  handleCheckboxChange = (e) => {
    const target = e.target;
    this.setState({ acceptedTerms: target.checked });
    this.updateSubmitLabel(e)
  }

  resetFieldState = () => { }

  renderShowTermsConditions = () => {
    if (LocalData.userRegistered) {
      return;
    }
    return (
      <div className='form-container-inline'>
        <input id="checkTerms" type="checkbox"
          checked={this.state.acceptedTerms}
          onChange={this.handleCheckboxChange} />
        <Button className='link-container' type='link'
          valueText={Labels.termsConditionsLinkText}
          valueLink={Labels.termsConditionsLink}
          onClick={() => this.showTermsAndConditions()} />
      </div>
    );
  }

  setPhoneCodeDirectly = (code) => {
    this.setState({ inputPhoneCode: code });
    if (this.phoneCodeRegex.test(code)) {
      this.setState({ phoneCode: code });
      this.onPhoneValidate(this.state.loginPhone, code);
    } else {
      this.setState({ phoneCode: '' });
    }
  };

  handleChangeEmailCode = (e) => {
    e.stopPropagation();
    const input = e.target.value;
    this.setState({ inputEmailCode: input });
    if (this.emailCodeRegex.test(input)) {
      this.setState({ emailCode: input });
      this.onEmailValidate(this.state.loginEmail, input);
    } else {
      this.setState({ emailCode: '' });
    }
  };

  handleChangePhoneCode = (e) => {
    e.stopPropagation();
    const input = e.target.value;
    this.setState({ inputPhoneCode: input });
    if (this.phoneCodeRegex.test(input)) {
      this.setState({ phoneCode: input });
      this.onPhoneValidate(this.state.loginPhone, input);
    } else {
      this.setState({ phoneCode: '' });
    }
  };

  onEmailValidate = (email, code) => {
    const token = this.cookies.get('sessionToken');
    const password = this.state.password;
    let body = { email: email, code: code, password: password };
    validateUserEmail(body, token).then((response) => {
      if (response) {
        if (response.type === "Failure" || response.code) {
          this.setState({
            emailCodeRequestSuccess: "Failure"
          });
          const timer = setTimeout(() => {
            this.setState({
              emailCodeRequestSuccess: ''
            });
          }, 5000);
          this.timerCleanup = () => clearTimeout(timer);
          if (response.code && response.code !== 2) {
            this.renderInfoModal("Pedido Sem Sucesso", "Foi encontrado um problema durante o pedido, por favor tente mais tarde.");
          }
        } else {
          this.renderInfoModal("Email validado.", "Já pode iniciar sessão com o seu Email!");

          this.setState({
            loginEmailValidated: true,
            loginEmail: response.data?.email,
            inputEmailValue: response.data?.email
          });
          this.getClient();
        }
      }
    });
  };

  onPhoneValidate = (phone, code) => {
    const token = this.cookies.get('sessionToken');
    let body = { phone: phone, code: code };
    validateUserPhone(body, token).then((response) => {
      if (response) {
        if (response.type === "Failure" || response.code) {  
          this.setState({
            phoneCodeRequestSuccess: "Failure"
          });
          const timer = setTimeout(() => {
            this.setState({
              phoneCodeRequestSuccess: ''
            });
          }, 5000);
          this.timerCleanup = () => clearTimeout(timer);
          if (response.code && response.code !== 2){
            this.renderInfoModal("Pedido Sem Sucesso", "Foi encontrado um problema durante o pedido, por favor tente mais tarde.");
          }
        } else {    
          this.renderInfoModal("Telefone validado.", "Já pode iniciar sessão com o seu telefone!");
          
          this.setState({
            loginPhoneValidated: true,
            loginPhone: response.data?.phone,
            inputPhoneValue: response.data?.phone
          });
          this.getClient()
        }
      }
    });
  };

  handleEmailCodeRequest  = (e) => {
    e.preventDefault();
    this.onEmailCodeRequest(this.state.loginEmail, this.state.password );
  }

  onEmailCodeRequest = (email, password) => {
    const token = this.cookies.get('sessionToken');
    const body = { email: email, password: password };

    requestEmailCodeToValidate(body, token).then((response) => {
      if (response) {
        if (response.type === "Success") {
          this.setState({ emailCodeRequested: true, emailCodeRequestSuccess: "Success" });

          const timer = setTimeout(() => {
            this.setState({ emailCodeRequestSuccess: "" });
          }, 10000);

          // Cleanup timer when the component unmounts or the effect changes
          this.timerCleanup = () => clearTimeout(timer);
        } else {
          this.setState({
            emailCodeRequested: false
          });
          this.renderInfoModal("Tente novamente", "Ocorreu um erro a enviar e-mail com código de validação.");
        }
      }
    });
  };

  handlePhoneCodeRequest  = () => {
    this.onPhoneCodeRequest(this.state.loginPhone);
  }

  onPhoneCodeRequest = (phone) => {
    const token = this.cookies.get('sessionToken');
    const platform = Helper.isAndroidWebview() ? "ANDROID" :
                      Helper.isIOSWebview() ? "IOS" :
                      "WEB";
    const body = { phone: phone, platform: platform };


    requestPhoneCodeToValidate(body, token).then((response) => {
      if (response) {
        if (response.type === "Success") {
          this.setState({ phoneCodeRequested: true, phoneCodeRequestSuccess: "Success" });
          this.receiveOtpBrowser(); // Listen to OTP on mobile browser

          const timer = setTimeout(() => {
            this.setState({ phoneCodeRequestSuccess: "" });
          }, 10000);

          // Cleanup timer when the component unmounts or the effect changes
          this.timerCleanup = () => clearTimeout(timer);
        } else {
          this.setState({
            phoneCodeRequested: false
          });
          if (response.http_code === 409 && response.code === 3) {
            this.renderInfoModal('Utilizador já Existente', response.message);
          } else {
            this.renderInfoModal("Tente novamente", "Ocorreu um erro a enviar SMS com código de validação.");
          }
        }
      }
    });
  };

  renderRequestPhoneOTPButton = () => {
    return(<>
      {this.state.loginPhone && !this.state.loginPhoneValidated && !this.state.phoneCodeRequested &&
      <button className="cta-button-secondary shadow short d-medium ms-2 mb-0" 
        onClick={this.handlePhoneCodeRequest} 
        type="button">
        Confirmar
      </button>}
    </>)
  }

  renderEmailCodeInput = () => {
    return (<>
      { this.state.loginEmail && !this.state.loginEmailValidated && this.state.emailCodeRequested && <>
        <div className="input-container mt-2">
          <p className="input-label">Introduza o código recebido no seu email</p>
          <input
            className={`input-medium-default unique-letter-spacing
            ${this.state.emailCodeRequestSuccess === "Success" ? "success" :
            this.state.emailCodeRequestSuccess === "Failure" ? "failure" : ''
            }`}
            type="text"
            inputMode="numeric" 
            pattern="[0-9]*"
            maxLength={6}
            autoFocus
            title="Código rebido por e-mail"
            value={this.state.inputEmailCode}
            placeholder="Código recebido no seu e-mail"
            onChange={this.handleChangeEmailCode}

          />
        </div>
        <div
          className={`link-container text-start mt-1 mb-3 ${this.state.emailCodeRequestSuccess === "Success" ? "success" :
            this.state.emailCodeRequestSuccess === "Failure" ? "failure" : ''
            }`}
          onClick={this.state.emailCodeRequestSuccess === '' ? this.handleEmailCodeRequest : undefined
        }
        >
          {
            this.state.emailCodeRequestSuccess === "Success" ? "Enviámos um Código para o seu e-mail." :
            this.state.emailCodeRequestSuccess === "Failure" ? "Código inválido ou expirado." :
                "Enviem-me novo código para o e-mail!"
          }
        </div>

      </>
      }
    </>);
  }

  renderSMSCodeInput = () => {
    return (<>
      { this.state.loginPhone && !this.state.loginPhoneValidated && this.state.phoneCodeRequested && <>
        <div className="input-container mt-2">
          <p className="input-label">Introduza o código recebido por SMS</p>
          <input
            className={`input-medium-default unique-letter-spacing
            ${this.state.phoneCodeRequestSuccess === "Success" ? "success" :
            this.state.phoneCodeRequestSuccess === "Failure" ? "failure" : ''
            }`}
            type="text"
            name="one-time-code"
            id="sms-code"
            inputMode="numeric" 
            pattern="[0-9]*"
            autoComplete="one-time-code" 
            maxLength={6}
            aria-label="One time password"
            autoFocus
            title="Código rebido por SMS"
            value={this.state.inputPhoneCode}
            placeholder="Código recebido por SMS"
            onChange={this.handleChangePhoneCode}

          />
        </div>
        <div
          className={`link-container text-start mt-1 mb-3 ${this.state.phoneCodeRequestSuccess === "Success" ? "success" :
            this.state.phoneCodeRequestSuccess === "Failure" ? "failure" : ''
            }`}
          onClick={this.state.phoneCodeRequestSuccess === '' ? this.handlePhoneCodeRequest : undefined
        }
        >
          {
            this.state.phoneCodeRequestSuccess === "Success" ? "Enviámos-lhe um Código por SMS." :
            this.state.phoneCodeRequestSuccess === "Failure" ? "Código inválido ou expirado." :
                "Enviem-me novo código SMS!"
          }
        </div>

      </>
      }
    </>);
  }


  handleEmailSignup = () =>  {
    const USER_AUTH = {
      email: this.state.loginEmail,
      password: this.state.password,
    }
      this.handleUserSignup(USER_AUTH);
  }
 
  handleUserSignup(USER_AUTH) {
    let body = { ...USER_AUTH };
    if (this.cookies.get('userId')) {
      body = {
        ...body,
        user_id: this.cookies.get('userId'),
        work_token: this.cookies.get('liteToken')
      };
    }
    userSignUp(body).then(response => {
      if (response) {
        if (response.http_code) {
          if (response.http_code === 401) {
            Helper.callLogError('401 userSignUp '+ this.cookies.get('userId') + ' ');
            return;
          } else if (response.http_code === 409 && response.code === 3) {
            this.renderInfoModal('Utilizador já Existente', response.message);
          }
          else if (response.http_code === 400) {
            this.renderInfoModal('Dados Incorrectos', response.message);
          } else {
            this.renderGenericError();
          }
        } else {
          //pop-up with instructions
          this.getClient();
        }
      } else {
        this.renderGenericError();
      }
    });
  }


  handleValidationCompletePassword = (isValid) => {
    if (isValid) { this.onEmailCodeRequest(this.state.loginEmail, this.state.password ); }
    this.setState({ validateNowPassword: false });
  };

  handlePasswordFormSubmit = (e) => {
    e.preventDefault();  // Prevent default form submission
    this.setState({ validateNowPassword: true });
  }

  renderRequestEmailOTPButton = () => {
    return (<>
      {this.state.loginEmail && !this.state.loginEmailValidated && !this.state.emailCodeRequested &&
        <button className="cta-button-secondary shadow short d-medium ms-2 mb-0" 
        onClick={this.handlePasswordFormSubmit} 
        type="button">Confirmar</button>
      }</>)
  }

  renderEmailPasswordField = () => {
    return (<>
                {this.state.loginEmail && !this.state.loginEmailValidated && <>
              <PasswordField password={this.state.password} 
              onChange={this.handleChangePassword} 
              validateNow={this.state.validateNowPassword}
              onValidationComplete={this.handleValidationCompletePassword}
              autoComplete="new-password" />
          </>}
    </>)
  }


  renderPreferencialContacts = () => {
    const handleKeyDownOnPhone = (event) => {
      //event.preventDefault();
      if (event.key === 'Enter' && this.state.loginPhone && !this.state.loginPhoneValidated && !this.state.phoneCodeRequested) { 
        this.handlePhoneCodeRequest(this.state.loginPhone); 
      }
    };
    const handleKeyDownOnEmail = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (this.state.loginEmail && !this.state.loginEmailValidated) {
          // Find the next input element
          const inputs = Array.from(document.querySelectorAll('input'));
          const currentIndex = inputs.findIndex(element => element === event.target);
          const nextInput = inputs[currentIndex + 1];
          // If there is a next input, focus it
          if (nextInput) {
            nextInput.focus();
          }
        }
      }
    };
    return (
      <div className="col-12 col-xl-6 mt-4 mt-xl-0">
        <div className='grey-box-container mw-100 w-100 m-0 big pb-4'>

        <h2 className='text-center w-100 mb-0'> Contactos preferenciais para {Helper.companyName()}</h2>
          <div className='inline-column w-100 mw-100 mt-0'>
            <div className='form-flex-container row'>
              <div className='inline-column col-12 col-md-6'>
                <InputField ref={this.refContactList} type='text' title='Telefone'
                  styleInput="input-medium-default"
                  valueFormat='phoneNumber' minLength={9} maxLength={13}
                  placeholder='Insira Contacto (Ex: +351987654321)'
                  list={true} selection={true} addButton={true} enterKeyPress={true} required={false} />
              </div>
              <div className='inline-column col-12 col-md-6'>
                <InputField ref={this.refEmailList} type='email'
                  pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                  styleInput="input-medium-default"
                  title='E-mail' valueFormat='default'
                  placeholder='Insira E-mail de Contacto'
                  list={true} selection={true} enterKeyPress={true}
                  addButton={true} required={false} />
              </div>
            </div>
          </div>
          <h2 className='text-center w-100 mb-3 mt-5'>Contactos para iniciar sessão na app Keymaster</h2>
          <div className='inline-column w-100 mw-100 mt-0'>
            <div className='form-flex-container row'>

              <div className='inline-column col-12 col-md-6'>
                <div className='input-label mb-1'>
                  {this.state.loginPhoneValidated ? 'Telefone para iniciar sessão' : 'Telefone para iniciar sessão - por confirmar'}
                </div>
                {this.state.loginPhoneValidated &&
                  <div className='input-label mt-0 mt-md-0 fw-bold'>
                    {this.state.loginPhone}
                  </div>
                }
                {!this.state.loginPhoneValidated && <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <input
                      type='text'
                      className={`input-medium-default mt-2 ${this.state.loginPhoneValidated ? 'disable' : ''}`}

                      style={{ flexGrow: 1 }}
                      title='Telefone'
                      value={this.state.inputPhoneValue}
                      minLength={9}
                      maxLength={13}
                      placeholder='Nº de Telemóvel de Portugal'
                      readOnly={this.state.loginPhoneValidated}
                      onChange={this.handleChangePhone}
                      onKeyDown={handleKeyDownOnPhone}
                      required={false}
                      name=''
                    />
                    {this.renderRequestPhoneOTPButton()}
                  </div>
                  {this.renderSMSCodeInput()}
                </>}
              </div>

              <div className='inline-column col-12 col-md-6'>

                <div className='input-label mb-1 mt-4 mt-md-0'>
                  {this.state.loginEmailValidated ? 'E-mail para iniciar sessão' : 'E-mail para iniciar sessão - por confirmar'}
                </div>
                {this.state.loginEmailValidated &&
                  <div className='input-label mt-0 mt-md-0 fw-bold'>
                    {this.state.loginEmail}
                  </div>
                }
                {!this.state.loginEmailValidated && <>
                  <input
                    type='email'
                    className={`input-medium-default mt-2 ${this.state.loginEmailValidated ? 'disable' : ''}`}
                    pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                    title='E-mail'
                    value={this.state.inputEmailValue}
                    placeholder='Insira E-mail de Contacto'
                    readOnly={this.state.loginEmailValidated}
                    onChange={this.handleEmailChange}
                    onKeyDown={handleKeyDownOnEmail}
                    required={false}
                  />
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {this.renderEmailPasswordField()}
                    {this.renderRequestEmailOTPButton()}
                    
                  </div>
                  {this.renderEmailCodeInput()}
                </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderClientDetails(disableNIF, selectedOption, countriesList) {
    return (
      <div className="col-12 col-xl-6">
        <div className="grey-box-container mw-100 w-100 m-0 big pb-4">
          <h2 className="text-center w-100 mb-0"> Dados de cliente para {Helper.companyName()}</h2>
          <div className="inline-column w-100 mw-100 mt-0">
            <div className="form-flex-container row">
              {/* NAME */}
              <div className="inline-column col-12 col-md-8">
                <InputField
                  ref={this.refName}
                  styleInput="input-medium-default"
                  type="text"
                  title="Nome"
                  placeholder="Nome e Apelido"
                  required={false}
                />
              </div>

              {/* COUNTRY mobile */}
              <div className="inline-column col-12 col-md-4 mt-3 d-md-none">
                <SelectList
                  selectedOption={selectedOption}
                  title={"País"}
                  isClearable={true}
                  onChange={(option) => this.updateCountry(option)}
                  options={countriesList}
                />
              </div>

              {/* NIF */}
              <div className="inline-column col-12 col-md-4">
                <InputField
                  ref={this.refNif}
                  type="text"
                  title="NIF"
                  styleInput={
                    disableNIF
                      ? "input-medium-default disabled"
                      : "input-medium-default"
                  }
                  placeholder="Insira NIF (Ex: 123456789)"
                  required={false}
                />
              </div>
            </div>

            <div className="form-flex-container row">
              {/* ADDRESS */}
              <div className="inline-column col-12 col-md-8">
                <InputField
                  ref={this.refAdress}
                  styleInput="input-medium-default"
                  type="text"
                  title="Morada"
                  placeholder="Inserir Morada"
                  required={false}
                />
              </div>
              {/* COUNTRY DESKTOP */}
              <div className="inline-column col-12 col-md-4 mt-3 d-none d-md-inline-block">
                <SelectList
                  selectedOption={selectedOption}
                  title={"País"}
                  isClearable={true}
                  onChange={(option) => this.updateCountry(option)}
                  options={countriesList}
                />
              </div>
            </div>
            <div className="form-flex-container row justify-content-start">
              {/* POSTAL CODE */}
              <div className="inline-column col-6 col-md-4">
                <InputField
                  ref={this.refZip}
                  styleInput="input-medium-default"
                  type="text"
                  title="Código Postal"
                  placeholder="Código Postal"
                  required={false}
                />
              </div>
              {/* LOCALITY */}
              <div className="inline-column col-6 col-md-4">
                <InputField
                  ref={this.refCity}
                  styleInput="input-medium-default"
                  type="text"
                  title="Localidade"
                  placeholder="Localidade"
                  required={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()} />
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  render() {
    const { selectedCountry, countriesList, disableNIF } = this.state;
    const selectedOption = selectedCountry ? { value: `${selectedCountry.id}`, label: selectedCountry.country } : null;

    return (
      <React.Fragment>
        <Prompt when={this.state.shouldBlockNavigation} message={Labels.changesOnProfileMessage} />
        <IAgreeBox url={window.location} />
        {LocalData.terms_accepted && (
          <div className="main-container px-3 pb-4">
            <div className="align-items-start grid-container p-0 pb-4 mt-0 justify-content-center">
              <form
                className="mt-4 w-100 row"
                onSubmit={this.handleSubmit}
                onChange={this.updateSubmitLabel}
              >
                {this.renderClientDetails(disableNIF, selectedOption, countriesList)}

                {this.renderPreferencialContacts()}

                <div className="justify-content-center row mt-4">
                  <div className="inline-column col-12 col-md-6 col-lg-4">
                    {this.renderShowTermsConditions()}
                    {this.state.shouldBlockNavigation && (
                      <Button
                        className="cta-button-primary d-medium w-100 px-md-5 mt-2"
                        type="submit"
                        nextRow={true}
                        value={this.state.submitLabel}
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
            <Modal ref={this.refModal} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(DadosCliente);