import {
  setPathVariable, GET_CONFIG, GET_SERVICES_SYMPTOMS, GET_SLOTS, DEFINE_CLIENT_NEW_PASSWORD, REGISTER_NEW_CLIENT, EMAIL_CONFIRMATION, AUTHENTICATE_USER,
  RECOVER_PASSWORD, USER_SIGN_UP, SERVICE_FEEDBACK, GET_CLIENT_DATA, UPDATE_CLIENT_DATA, DELETE_CLIENT_DATA, ALTER_USER_PASSWORD, GET_ITEM_LIST,
  ADD_ITEM_TO_USER, GET_FO_LIST, CANCEL_FO, GET_FO_DETAILS, SCHEDULE_SERVICE, GET_ITEM_INFO, CHANGE_ITEM_IMAGE,
  PRE_REGISTER_NEW_CLIENT, EMAIL_REGISTER_CONFIRM, REMOVE_ITEM_FROM_USER, GET_AVAILABLE_ESTABLISHMENT, GET_TERMS_SHOP,
  ESTABLISHMENT_FEEDBACK, APP_FEEDBACK, ESTABLISHMENT_DISTANCE, ALL_ESTABLISHMENT_DISTANCE, GET_WORKS_SCHEDULE, GET_WORKS_INPROGRESS, GET_WORKS_COMPLETED,
  SUPORT_REQUEST, ALTER_ITEM_DETAIL, GET_ITEM_WORKS_SCHEDULE, GET_ITEM_WORKS_INPROGRESS, GET_ITEM_WORKS_COMPLETED,
  RESET_ITEM_DETAILS, GET_COUNTRIES_DATA, SUBMIT_INVOICE, SUBMIT_APPROVE_SERVICE, GET_CLUSTER_COOKIE, GET_COMPANIES, GET_NEW_COMPANIES, GET_COMPANIES_COUNT,
  AUTHENTICATE_PROVIDER_USER, AUTHENTICATE_DEMO_USER, PRE_SIGNUP, VALIDATE_USER_PHONE, REQUEST_PHONE_CODE_TO_VALIDATE, VALIDATE_USER_EMAIL, REQUEST_EMAIL_CODE_TO_VALIDATE,
  PUSH_WORK_ANALYTICS, NOTIFICATIONS_LIST, NOTIFICATION_READ,
  NOTIFICATION_DELETE,
  NOTIFICATIONS_LIST_CHANGES,
  NOTIFICATION_READ_ALL
} from '../../api/variables/endpoint.collection';

import {
  SYMPTOM, SLOT, TOKEN, CLIENT_DETAILS, ITEM_DETAILS_WITH_SERVICES, SERVICE_WITH_ITEM,
  SERVICE_DETAILS, ITEM_DETAILS_WITHS_SERVICES_AND_SCRAPING, CONFIG_DATA
} from '../../api/models/api.models';
import { ADD_CLIENT_MEDIA, ADD_CLIENT_OBS, EDIT_PERSONAL_OBS, GET_SHOWCASE_WORK, GET_SHOWCASE_WORK_CAL, POST_SHOWCASE_CAL_CONFIRM, GET_UPLOAD_CONFIG, LOG_LITE, PUSH_EVENT_ANALYTICS, READ_OBSERVATIONS, READ_SERVICE_CHECK, REMOVE_CLIENT_MEDIA } from '../variables/endpoint.collection';
import LocalData from '../../core/localData';
import Helper from '../../core/helper/helper';
import appConfig from '../../core/appConfig';
import ScheduleService from '../../views/schedule/core/services/schedule.service';

const USE_REAL_API = true; // change to `false` when needed

// helper function
// used as: delayedPromise(1000, "hey")
// will return a promise that will resolve with "hey" in 1000ms.
function delayedPromise(ms, value) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(value), ms);
  });
}

function fetchCookieData(endpoint, method, headers) {
  if (!USE_REAL_API) {
    return delayedPromise(1, 'kssCookieMock');
  }
  return fetch(appConfig.BASE_CLUSTER_URL + endpoint, { method, headers })
    .then(response => {
      if (response.status === 404) {
        return response.json().then(data => {
          const body = { ...data, code: 404 };
          return Promise.resolve(body);
        });
      }
      return response;
    })
    .catch((error) => {
      console.error(error);
      const body = { code: 404 };
      return Promise.resolve(body);
    }
  );
}

// TODO: Add Doc as above
function fetchData(endpoint, method, headers, body, mock) {
  if (body != null) {
    body = JSON.stringify(body);
  }
  if (USE_REAL_API) {
    return fetch(appConfig.BASE_URL + endpoint, {
      method,
      headers,
      body,
    }).then((response) => {
      if (response.status === 401) {
        if (Helper.isDemo()){ //401 in Demo mode has special treatment
          return response.json();
        }
        if (Helper.isLiteVersion()) {
          Helper.callLogError('401 fetchData isLite '+ endpoint);
          Helper.logoutFromLite();
          return;
        }
        Helper.callLogError('401 fetchData isNotLite '+ endpoint);
        Helper.logout();
      } else if (response.status === 301) {
        return response.json().then(data => {
          const body = { ...data, code: 301 };
          return Promise.resolve(body);
        });
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
  return delayedPromise(1, mock);
}

function fetchDataIgnoreErrors(endpoint, method, headers, body, mock) {
  if (body != null) {
    body = JSON.stringify(body);
  }
  if (USE_REAL_API) {
    return fetch(appConfig.BASE_URL + endpoint, {
      method,
      headers,
      body,
    }).then((response) => {
      if (response.ok && response.status !== 204) { // Check for success and not empty response
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return response.json();
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).catch((error) => {
      console.error(error);
      return null;
    });
  }
  return delayedPromise(1, mock);
}

function fetchCalData(endpoint, method, headers, body, mock) {
  if (body != null) {
    body = JSON.stringify(body);
  }
  if (USE_REAL_API) {
    return fetch(appConfig.BASE_CAL_URL + endpoint, {
      method,
      headers,
      body,
    }).then((response) => {
      if (response.status === 401) {
        if (Helper.isLiteVersion()) {
          Helper.callLogError('401 fetchData isLite '+ endpoint);
          Helper.logoutFromLite();
          return;
        }
        Helper.callLogError('401 fetchData isNotLite '+ endpoint);
        Helper.logout();
      } else if (response.status === 301) {
        return response.json().then(data => {
          const body = { ...data, code: 301 };
          return Promise.resolve(body);
        });
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      console.error(error);
      return {
        code: 500,
        message: 'An error occurred' // You can include more details if necessary
      };
    });
  }
  return delayedPromise(1, mock);
}

function fetchFormData(endpoint, method, headers, body, mock) {
  if (USE_REAL_API) {
    return fetch(appConfig.BASE_URL + endpoint, {
      method,
      headers,
      body,
    }).then((response) => response.json())
    .catch((error) => {
      console.error(error);
    });
  }
  return delayedPromise(500, mock);
}

function fetchHeadersWithAuth(token) {
  return {
    Accept: 'application/json', 'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
}

function fetchHeadersWithToken(token) {
  const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  // Add Clienttoken header if token is not null
  if (token) {
    baseHeaders.Clienttoken = `Bearer ${token}`;
  }
  // Add development-specific headers
  if (process.env.NODE_ENV === 'development') {
    baseHeaders['x-domain'] = 'checkpoint';
    //baseHeaders['x-domain'] = 'comdepcomexp';
    baseHeaders['x-password'] = 'mfBYezEmywACY0eaIzSC';
  }

  return baseHeaders;

}

// function fetchHeadersRegistrationToken(token) {
//   return {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     'X-REGISTRATION-TOKEN': token,
//   };
// }

function fetchHeaders() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

function fetchHeadersEmpty() {
  return { };
}

function fetchHeadersWithMultipart(token) {
  return {
    Accept: 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // 'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  };
}

function fetchHeadersLiteWithMultipart() {
  return {
    Accept: 'application/json',
  };
}

// #############################
// ###      CONFIG    ###
// #############################
function getAppConfig() {
  return fetchData(GET_CONFIG, 'GET', fetchHeaders(), null, generateConfigMockData());
  // return delayedPromise(100, generateConfigMockData());
}
function getClusterCookie() {
  return fetchCookieData(GET_CLUSTER_COOKIE, 'GET', fetchHeaders(), null, null);
}
function getUploadConfig(TOKEN) {
  return fetchData(GET_UPLOAD_CONFIG, 'GET', fetchHeadersWithAuth(TOKEN), null, null);
  // return delayedPromise(100, generateConfigMockData());
}

function postLogLite(BODY) {
  return fetchData(LOG_LITE, 'POST', fetchHeaders(), BODY, null);
}


// #############################
// ###      AGENDAMENTO      ###
// #############################

/**
 * Chamada utilizada para obter a lista de serviços (sintomas) disponíveis na plataforma Keymaster
 * para o utilizador efectuar a sua selecção.
 * @returns   array[Symptom]
 * @responses 200 (Lista sintomas disponiveis),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function getServiceSymptoms(TOKEN) {
  return fetchData(GET_SERVICES_SYMPTOMS, 'GET', fetchHeadersWithAuth(TOKEN), null, generateGetServiceSymptomsMockData());
}

/**
 * Chamada utilizada para obter a lista de serviços (sintomas) disponíveis na plataforma Keymaster
 * para o utilizador efectuar a sua selecção.
 * @returns   array[Slot]
 * @responses 200 (Lista de slots disponiveis),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function getSlots(TOKEN, QUERY) {
  return fetchData(GET_SLOTS + QUERY, 'GET', fetchHeadersWithAuth(TOKEN), null, generateGetSlotsMockData());
}

function getAvailableEstablishments(TOKEN, QUERY, BODY) {
  return fetchData(GET_AVAILABLE_ESTABLISHMENT + QUERY, 'POST', fetchHeadersWithAuth(TOKEN), BODY, generateGetServiceSymptomsMockData());
}

function getAllEstablishmentDistance(TOKEN, BODY) {
  return fetchData(ALL_ESTABLISHMENT_DISTANCE, 'POST', fetchHeadersWithAuth(TOKEN), BODY, generateGetServiceSymptomsMockData());
}

function getEstablishmentDistance(TOKEN, ID, BODY) {
  return fetchData(setPathVariable(ESTABLISHMENT_DISTANCE, 'id', ID), 'POST', fetchHeadersWithAuth(TOKEN), BODY, generateItemInfoMockData());
}

function getEstablishmentTerms(TOKEN) {
  return fetchData(GET_TERMS_SHOP, 'GET', fetchHeadersWithAuth(TOKEN), null, generateItemInfoMockData());
  // return fetchData(setPathVariable(GET_TERMS_SHOP, 'id', ID), 'GET', fetchHeadersWithAuth(TOKEN), null, generateItemInfoMockData());
}

async function getCompanyIAgreeData(DOMAIN) {
  try {
    const response = await fetchData(`${GET_TERMS_SHOP}?value=${DOMAIN}`,'GET', fetchHeaders(), null,generateItemInfoMockData());
    return response.data;
  } catch (error) {
    console.error('Error fetching company I agree data:', error);
    throw error; // Re-throw the error if needed for further handling
  }
}


// #############################
// ###     Autenticação      ###
// #############################
function initialPreSignup(TOKEN, prevCompanyDomain, iagree = false) {
  const headers = fetchHeadersWithAuth(TOKEN);
  if (prevCompanyDomain) {
    headers['Prevcompanydomain'] = `${prevCompanyDomain}`;
  }
  
  // Add terms_accepted query parameter if iagree is true
  const url = iagree ? `${PRE_SIGNUP}?terms_accepted=true` : PRE_SIGNUP;
  
  return fetchData(url, 'GET', headers, null);
}

function confirmEmail(CONFIRM_TOKEN) {
  return fetchData(EMAIL_REGISTER_CONFIRM + CONFIRM_TOKEN, 'GET', fetchHeadersEmpty(), null);
}
/**
 * Chamada utilizada para o utilizador definir uma nova palavra-chave associada à sua conta Keymaster.
 * @responses 202 (Password aceite),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function defineNewPasswordForClient(PASSWORD_RECOVER, CONFIRM_TOKEN) {
  return fetchData(DEFINE_CLIENT_NEW_PASSWORD + CONFIRM_TOKEN, 'PATCH', fetchHeaders(), PASSWORD_RECOVER, '');
}

/**
 * Chamada utilizada para escrever os dados do cliente na base de dados Keymaster.
 * @reponses 200 (Dados inseridos com sucesso),
 *           400 (O input é inválido - Error),
 *           401 (O token de sessão é inválido - Error),
 *           default (Erro inesperado de servidor - Error)
 */
function registerNewClient(NEW_CLIENT_DETAILS) {
  return fetchData(REGISTER_NEW_CLIENT, 'POST', fetchHeaders(), NEW_CLIENT_DETAILS, '');
}

/**
 * Chamada utilizada para solicitar um novo e-mail de confirmação, para o caso do utilizador não conseguir localizar o e-mail enviado
 * logo após o registo.
 * @returns  TOKEN
 * @reponses 202 (Pedido para reenvio de email confirmação aceite - Token),
 *           default (Erro inesperado de servidor - Error)
 */
function sendNewEmailConfirmation(USER_AUTH) {
  return fetchData(EMAIL_CONFIRMATION, 'POST', fetchHeaders(), USER_AUTH, generateTokenMockData());
}

/**
 * Chamada utilizada para autenticar um utilizador na plataforma Keymaster através do seu endereço de e-mail e palavra-chave.
 * @returns   TOKEN
 * @responses 201 (Token de Sessão - Token),
 *            400 (Mensagem de erro devido a input errado - Error),
 *            401 (Mensagem de erro devido a dados de acesso invalidos ou falta de confirmação - Error)
 *            default (Erro inesperado de servidor - Error)
 */
function authenticateUser(USER_AUTH) {
  return fetchData(AUTHENTICATE_USER, 'POST', fetchHeaders(), USER_AUTH, generateTokenMockData());
}

/**
 * Chamada utilizada para autenticar um utilizador na plataforma Keymaster através do token jwt fornecido pela Google.
 * @returns   TOKEN
 * @responses 201 (Token de Sessão - Token),
 *            400 (Mensagem de erro devido a input errado - Error),
 *            401 (Mensagem de erro devido a dados de acesso invalidos - Error)
 *            default (Erro inesperado de servidor - Error)
 */
function authenticateProviderUser(PROVIDER_TOKEN) {
  return fetchData(AUTHENTICATE_PROVIDER_USER, 'POST', fetchHeaders(), PROVIDER_TOKEN, generateTokenMockData());
}

/**
 * Chamada utilizada para autenticar um utilizador numa empresa DEMO
 * @returns   TOKEN
 * @responses 201 (Token de Sessão - Token),
 *            400 (Mensagem de erro devido a input errado - Error),
 *            401 (Mensagem de erro devido a dados de acesso invalidos - Error)
 *            default (Erro inesperado de servidor - Error)
 */
function authenticateDemoUser(DEMOAUTH) {
  return fetchData(AUTHENTICATE_DEMO_USER, 'POST', fetchHeaders(), DEMOAUTH, generateTokenMockData());
}

/**
 * Chamada utilizada para recuperar a conta do utilizador, para o caso deste se ter esquecido da sua palavra-chave.
 * @responses 202 (Recuperação conta utilizador),
 *            default (Erro inesperado de servidor - Error)
 */
function recoverUserPassword(USER_EMAIL) {
  return fetchData(RECOVER_PASSWORD, 'POST', fetchHeaders(), USER_EMAIL, '');
}

/**
 * Chamada utilizada para criar uma nova conta Keymaster através de um endereço de e-mail e palavra-chave.
 * @returns   TOKEN
 * @responses 201 (Token de Sessão - Token),
 *            400 (Mensagem de erro devido a input errado - Error),
 *            401 (Mensagem de erro devido a dados de acesso invalidos ou email já registado - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function userSignUp(USER_AUTH) {
  return fetchData(USER_SIGN_UP, 'POST', fetchHeaders(), USER_AUTH, generateTokenMockData());
}

/**
 * Chamada utilizada para iniciar o registo após criação de conta.
 * @returns   Registration TOKEN
 * @responses 201 (Token de Sessão - Token),
 *            400 (Mensagem de erro devido a input errado - Error),
 *            401 (Mensagem de erro devido a dados de acesso invalidos ou email já registado - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function preUserSignUp(USER_AUTH = null, TOKEN) {
  if (TOKEN) {
    return fetchData(PRE_REGISTER_NEW_CLIENT, 'POST', fetchHeadersWithAuth(TOKEN), null, generateTokenMockData());
  }
  return fetchData(PRE_REGISTER_NEW_CLIENT, 'POST', fetchHeaders(), USER_AUTH, generateTokenMockData());
}

/**
 * Chamada utilizada para pedir OTP via SMS
 * @param {
 * } body 
 * @param {*} TOKEN 
 * @returns 
 */
function requestPhoneCodeToValidate(body, TOKEN) {

  return fetchData(REQUEST_PHONE_CODE_TO_VALIDATE, 'POST', fetchHeadersWithAuth(TOKEN), body, generateTokenMockData());
}


/**
 * Chamada utilizada para pedir OTP via email
 * @param {
* } body 
* @param {*} TOKEN 
* @returns 
*/
function requestEmailCodeToValidate(body, TOKEN) {

  return fetchData(REQUEST_EMAIL_CODE_TO_VALIDATE, 'POST', fetchHeadersWithAuth(TOKEN), body, generateTokenMockData());
}


/**
 * Chamada utilizada para validar telefone+OTP
 * @param {*} body 
 * @param {*} TOKEN 
 * @returns 
 */
function validateUserPhone(body, TOKEN) {
  return fetchData(VALIDATE_USER_PHONE, 'POST', fetchHeadersWithAuth(TOKEN), body, generateTokenMockData());
}

/**
 * Chamada utilizada para validar email+OTP
 * @param {*} body 
 * @param {*} TOKEN 
 * @returns 
 */
function validateUserEmail(body, TOKEN) {
  return fetchData(VALIDATE_USER_EMAIL, 'POST', fetchHeadersWithAuth(TOKEN), body, generateTokenMockData());
}

// #############################
// ###        Conta          ###
// #############################

/**
 * Chamada utilizada para obter a lista de países conhecida em sistema.
 * @returns   countries
 * @responses 200 (lista de países - countries),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function getCountriesListData() {
  return fetchData(GET_COUNTRIES_DATA, 'GET', fetchHeadersEmpty(), null, '');
}

/**
 * Chamada utilizada para obter a lista de empresas conhecida em sistema.
 * @returns   empresas
 * @responses 200 (lista de empresas),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
 function getCompaniesListData(TOKEN) {
  return fetchData(GET_COMPANIES, 'GET', fetchHeadersWithAuth(TOKEN), null, LocalData.companies);
}

/**
 * Chamada utilizada para obter a lista de empresas conhecida em sistema.
 * @returns   empresas
 * @responses 200 (lista de empresas),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function getNewCompaniesListData(TOKEN) {
  const path = GET_NEW_COMPANIES;
  return fetchData(path, 'GET', fetchHeadersWithAuth(TOKEN), null, LocalData.companies);
}

/**
 * Chamada utilizada para obter a contagem de lista de empresas conhecida em sistema.
 * @returns   contagem de empresas
 * @responses 200 (contagem de empresas),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
 function getCompaniesCountData(TOKEN) {
  return fetchData(GET_COMPANIES_COUNT, 'GET', fetchHeadersWithAuth(TOKEN), null, {});
}

/**
 * Chamada utilizada para obter os dados do cliente da base de dados Keymaster.
 * @returns   CLIENT_DETAILS
 * @responses 200 (Detalhes do cliente - CLIENT_DETAILS),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function getClientData(TOKEN) {
  return fetchData(GET_CLIENT_DATA, 'GET', fetchHeadersWithAuth(TOKEN), null, generateClientDetailsMockData());
}

/**
 * Chamada utilizada para alterar os dados do cliente na base de dados Keymaster.
 * @responses 200 (Dados alterados com sucesso),
 *            400 (O input é inválido - Error),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function updateClientData(TOKEN, NEW_CLIENT_DETAILS) {
  return fetchData(UPDATE_CLIENT_DATA, 'PATCH', fetchHeadersWithAuth(TOKEN), NEW_CLIENT_DETAILS, '');
}

/**
 * Chamada utilizada para APAGAR os dados do cliente na base de dados Keymaster.
 * @responses 200 (Dados removidos com sucesso),
 *            400 (O input é inválido - Error),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function deleteClientData(TOKEN) {
  return fetchData(DELETE_CLIENT_DATA, 'DELETE', fetchHeadersWithAuth(TOKEN), null, '');
}

/**
 * Chamada utilizada para o utilizador alterar a sua palavra-chave.
 * @responses 200 (Palavra passe alterada),
 *            default (Erro inesperado de servidor - Error)
 */
function alterUserPassword(TOKEN, NEW_PASSWORD) {
  return fetchData(ALTER_USER_PASSWORD, 'PUT', fetchHeadersWithAuth(TOKEN), NEW_PASSWORD, '');
}

// #############################
// ###       GARAGEM         ###
// #############################

/**
 * Chamada utilizada para obter a lista de equipamentos associada à conta do utilizador.
 * @returns   array[ITEM_DETAILS_WITH_SERVICES]
 * @responses 200 (Detalhes dos veículos),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function getItemList(TOKEN) {
  return fetchData(GET_ITEM_LIST, 'GET', fetchHeadersWithAuth(TOKEN), null, generateVihecleListMockData());
}

/**
 * Chamada utilizada para adicionar uma equipamento à conta do utilizador.
 * @returns TOKEN
 * @responses 200 (Equipamento adicionada ao utilizador),
 *            400 (O input é inválido - Error),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function addItemToUser(TOKEN, ITEM) {
  return fetchData(ADD_ITEM_TO_USER, 'POST', fetchHeadersWithAuth(TOKEN), ITEM, generateTokenMockData());
}

/**
 * Chamada utilizada para obter o detail de uma equipamento seleccionada pelo utilizador.
 * @param     itemId (Required), ID do veiculo do utilizador [format: int64]
 * @returns   ITEM_DETAILS_WITHS_SERVICES_AND_SCRAPING
 * @responses 200 (Detalhes do veículo - ItemDetailsWithServicesAndScraping),
 *            401 (O token de sessão é inválido - Error),
 *            404 (O id pesquisado não existe - Error),
 *            default (Erro inesperado de servidor - Error)
 */
const getItemInfo = (itemId, TOKEN) => fetchData(setPathVariable(GET_ITEM_INFO, 'id', itemId), 'GET', fetchHeadersWithAuth(TOKEN), null, generateItemInfoMockData());

/**
 * Chamada utilizada para carregar para o servidor uma nova imagem associada a uma equipment.
 * @param     itemId (Required), ID do veiculo do utilizador [format: int64]
 * @returns   TOKEN
 * @responses 200 (Imagem adicionada à equipamento - Token),
 *            400 (O input é inválido - Error),
 *            401 (O token de sessão é inválido - Error),
 *            404 (O id pesquisado não existe - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function changeItemImage(TOKEN, ITEM_ID, IMAGE) {
  return fetchFormData(setPathVariable(CHANGE_ITEM_IMAGE, 'id', ITEM_ID), 'POST', fetchHeadersWithMultipart(TOKEN), IMAGE, generateTokenMockData());
}

function alterItemDetails(TOKEN, ITEM_ID, ITEM) {
  return fetchData(setPathVariable(ALTER_ITEM_DETAIL, 'id', ITEM_ID), 'PUT', fetchHeadersWithAuth(TOKEN), ITEM, generateTokenMockData());
}

function removeItemFromUser(ITEM_ID, TOKEN) {
  return fetchData(setPathVariable(REMOVE_ITEM_FROM_USER, 'id', ITEM_ID), 'DELETE', fetchHeadersWithAuth(TOKEN), null, generateFoDetailsMockData());
}

function resetItemDetails(ITEM_ID, TOKEN) {
  return fetchData(setPathVariable(RESET_ITEM_DETAILS, 'id', ITEM_ID), 'POST', fetchHeadersWithAuth(TOKEN), null, generateFoDetailsMockData());
}

// #############################
// ###       Services        ###
// #############################

/**
 * Chamada utilizada para obter toda a informação da obra a mostrar na versão Lite.
 * @param     ID | ID da obra a mostrar na versão Lite
 * @returns   SERVICE_DETAILS
 * @responses 200 (Details de um serviço),
 *            401 (O token de sessão é inválido - Error),
 *            404 (O id pesquisado não existe - Error),
 *            default (Erro inesperado de servidor - E  rror)
 */
async function getShowcaseWorkDetails(ID, TOKEN) {
  const response = await fetchData(setPathVariable(GET_SHOWCASE_WORK, 'id', ID), 'GET', fetchHeadersWithAuth(TOKEN), null);
  return response;
}

/**
 * Chamada utilizada para obter toda a informação do agendamento a mostrar na versão Lite.
 * @param     ID | ID do agendamento a mostrar na versão Lite
 * @returns   SERVICE_DETAILS
 * @responses 200 (Details de um serviço),
 *            401 (O token de sessão é inválido - Error),
 *            404 (O id pesquisado não existe - Error),
 *            default (Erro inesperado de servidor - E  rror)
 */
async function getShowcaseWorkDetailsCal(ID, TOKEN) {
  const response = await fetchCalData(setPathVariable(GET_SHOWCASE_WORK_CAL, 'id', ID), 'GET', fetchHeadersWithToken(TOKEN), null);
  return response;
}

/**
 * Chamada utilizada para confirmar um agendamento na versão Lite.
 * @param     ID | ID do agendamento a confirmar na versão Lite
 * @responses 200 ,
 *            401 (O token de sessão é inválido - Error),
 *            404 (O id pesquisado não existe - Error),
 *            default (Erro inesperado de servidor - E  rror)
 */
async function postShowcaseCalConfirm(ID, TOKEN) {
  const response = await fetchCalData(setPathVariable(POST_SHOWCASE_CAL_CONFIRM, 'id', ID), 'POST', fetchHeadersWithToken(TOKEN), {}, null);
  return response;
}

/**
 * Chamada utilizada para obter a lista completa de FOs associadas a uma conta de utilizador.
 * @returns   SERVICE_WITH_ITEM
 * @responses 200 (Lista de serviços - ServiceWithItem),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function getFoListForClient(TOKEN) {
  return fetchData(GET_FO_LIST, 'GET', fetchHeadersWithAuth(TOKEN), null, generateFoListMockData());
}

/**
 * Chamada utilizada para cancelar uma FO.
 * @param     foId (Required), ID do serviço a cancelar [format: int64]
 * @responses 200 (Cancelamento aceite),
 *            401 (O token de sessão é inválido - Error),
 *            404 (O id pesquisado não existe - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function cancelFo(FO_ID, TOKEN) {
  return fetchData(setPathVariable(CANCEL_FO, 'id', FO_ID), 'DELETE', fetchHeadersWithAuth(TOKEN), {}, '');
}

/**
 * Chamada utilizada para obter o detail de uma FO seleccionada pelo utilizador.
 * @param     service (Required), ID do serviço a pesquisar [format: int64]
 * @returns   SERVICE_DETAILS
 * @responses 200 (Details de um serviço),
 *            401 (O token de sessão é inválido - Error),
 *            404 (O id pesquisado não existe - Error),
 *            default (Erro inesperado de servidor - Error)
 */
async function getFoDetails(SERVICE_ID, TOKEN) {
  const response = await fetchData(setPathVariable(GET_FO_DETAILS, 'id', SERVICE_ID), 'GET', fetchHeadersWithAuth(TOKEN), null, generateFoDetailsMockData());
  return response;
}

/**
 * Chamada utilizada para reservar um slot de agendamento para o serviço seleccionado pelo utilizador.
 * @responses 201 (Serviço agendado),
 *            400 (O input é inválido - Error),
 *            401 (O token de sessão é inválido - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function scheduleService(TOKEN, BODY) {
  return fetchData(SCHEDULE_SERVICE, 'POST', fetchHeadersWithAuth(TOKEN), BODY, '');
}

async function getScheduleDetails(TOKEN, ID){
  const response = await fetchData(setPathVariable(GET_FO_DETAILS, 'id', ID), 'GET', fetchHeadersWithAuth(TOKEN), null, generateFoDetailsMockData());

  if (response && !response.code) {
    if (response.iso_date_start) {
      response.date_start = ScheduleService.convertDateFromISOPendingAppointment(response.iso_date_start);
      response.time = ScheduleService.getTimeFromISO(response.iso_date_start);
      response.shareCalendar_start_time = ScheduleService.convertDateFromISOCalendarShare(response.iso_date_start);
      delete response.iso_date_start;
    }
    if (response.iso_old_date_start) {
      response.old_date = ScheduleService.convertDateFromISOPendingAppointment(response.iso_old_date_start);
      response.old_time = ScheduleService.getTimeFromISO(response.iso_old_date_start);
      delete response.iso_old_date_start;
    }
    if (response.iso_date_end) {
      response.date_end = ScheduleService.convertDateFromISOPendingAppointment(response.iso_date_end);
      response.time_end = ScheduleService.getTimeFromISO(response.iso_date_end);
      response.shareCalendar_end_time = ScheduleService.convertDateFromISOCalendarShare(response.iso_date_end);
      delete response.iso_date_end;
    }
  }
  return response;
}

async function getScheduleServices(TOKEN) {
  const PATH = GET_WORKS_SCHEDULE;
  const response = await fetchData(PATH, 'GET', fetchHeadersWithAuth(TOKEN), null, '');
  // Format date in the response
  if (response && response.works) {
    response.works.forEach(item => {
      if (item.iso_date_start) {
        item.date_start = ScheduleService.convertDateFromISOPendingAppointment(item.iso_date_start);
        item.time = ScheduleService.getTimeFromISO(item.iso_date_start);
        delete item.iso_date_start;
      }
      if (item.iso_date_end) {
        item.date_end = ScheduleService.convertDateFromISOPendingAppointment(item.iso_date_end);
        item.time_end = ScheduleService.getTimeFromISO(item.iso_date_end);
        delete item.iso_date_end;
      }
      if (item.iso_old_date_start) {
        item.old_date = ScheduleService.convertDateFromISOPendingAppointment(item.iso_old_date_start);
        item.old_time = ScheduleService.getTimeFromISO(item.iso_old_date_start);
        delete item.iso_old_date_start;
      }
      item.is_history = item.is_history === true ? true : false;
    });
  }
  return response;
}

function getInProgressServices(TOKEN, ID = null) {
  let PATH = GET_WORKS_INPROGRESS;
  if (ID) {
    PATH = `${GET_WORKS_INPROGRESS}?itemId=${ID}`;
  }
  return fetchData(PATH, 'GET', fetchHeadersWithAuth(TOKEN), null, '');
}

function getCompletedServices(TOKEN, ID = null) {
  let PATH = GET_WORKS_COMPLETED;
  if (ID) {
    PATH = `${GET_WORKS_COMPLETED}?itemId=${ID}`;
  }
  return fetchData(PATH, 'GET', fetchHeadersWithAuth(TOKEN), null, '');
}


function getItemScheduleServices(TOKEN, ID) {
  return fetchData(setPathVariable(GET_ITEM_WORKS_SCHEDULE, 'id', ID), 'GET', fetchHeadersWithAuth(TOKEN), null, '');
}

function getItemInProgressServices(TOKEN, ID) {
  return fetchData(setPathVariable(GET_ITEM_WORKS_INPROGRESS, 'id', ID), 'GET', fetchHeadersWithAuth(TOKEN), null, '');
}

function getItemCompletedServices(TOKEN, ID) {
  return fetchData(setPathVariable(GET_ITEM_WORKS_COMPLETED, 'id', ID), 'GET', fetchHeadersWithAuth(TOKEN), null, '');
}

function requestInvoice(ID, TOKEN, BODY) {
  return fetchData(setPathVariable(SUBMIT_INVOICE, 'id', ID), 'POST', fetchHeadersWithAuth(TOKEN), BODY, '');
}

function submitApproveApi(ID, TOKEN) {
  return fetchData(setPathVariable(SUBMIT_APPROVE_SERVICE, 'id', ID), 'POST', fetchHeadersWithAuth(TOKEN), null, '');
}

function readServiceObs(ID, TOKEN) {
  return fetchData(setPathVariable(READ_OBSERVATIONS, 'id', ID), 'POST', fetchHeadersWithAuth(TOKEN), null, '');
}

function readServiceCheck(ID, TOKEN) {
  return fetchData(setPathVariable(READ_SERVICE_CHECK, 'id', ID), 'POST', fetchHeadersWithAuth(TOKEN), null, '');
}

function addClientServiceObs(ID, TOKEN, BODY) {
  return fetchData(setPathVariable(ADD_CLIENT_OBS, 'id', ID), 'POST', fetchHeadersWithAuth(TOKEN), BODY, '');
}

function editPersonalServiceObs(ID, TOKEN, BODY) {
  return fetchData(setPathVariable(EDIT_PERSONAL_OBS, 'id', ID), 'POST', fetchHeadersWithAuth(TOKEN), BODY, '');
}


function addMediaFile(TOKEN, ID, FILE) {
  if (Helper.isLiteVersion()) {
    return fetchFormData(setPathVariable(ADD_CLIENT_MEDIA, 'id', ID), 'POST', fetchHeadersLiteWithMultipart(), FILE, generateTokenMockData());
  } else {
    return fetchFormData(setPathVariable(ADD_CLIENT_MEDIA, 'id', ID), 'POST', fetchHeadersWithMultipart(TOKEN), FILE, generateTokenMockData());
  }
}

function removeMediaFile(TOKEN, ID, FILE_ID) {
  return fetchFormData(setPathVariable(REMOVE_CLIENT_MEDIA, 'id', ID, 'media_id', FILE_ID), 'DELETE', fetchHeadersWithAuth(TOKEN), '', generateTokenMockData());
}


// #############################
// ###   FEEDBACK/SUPORT     ###
// #############################

function requestSuport(TOKEN, BODY) {
  return fetchData(SUPORT_REQUEST, 'POST', fetchHeadersWithAuth(TOKEN), BODY, generateFoListMockData());
}

function submitApplicationFeedback(TOKEN, BODY) {
  return fetchData(APP_FEEDBACK, 'POST', fetchHeadersWithAuth(TOKEN), BODY, generateFoListMockData());
}

function submitEstablishmentFeedback(TOKEN, BODY) {
  return fetchData(ESTABLISHMENT_FEEDBACK, 'POST', fetchHeadersWithAuth(TOKEN), BODY, generateFoListMockData());
}

/**
 * Chamada utilizada para o utilizador enviar feedback sobre o serviço prestado pela oficina.
 * @param     serviceId (Required), ID do serviço a fornecer feedback [format: int64]
 * @responses 200 (Mensagem com confirmação pedido),
 *            401 (O token de sessão é inválido - Error),
 *            404 (O recurso não foi encontrado - Error),
 *            default (Erro inesperado de servidor - Error)
 */
function provideServiceFeedback(TOKEN, SERVICE_ID, FEEDBACK) {
  return fetchData(setPathVariable(SERVICE_FEEDBACK, 'id', SERVICE_ID), 'POST', fetchHeadersWithAuth(TOKEN), FEEDBACK, '');
}

// #############################
// ###      Analytics        ###
// #############################

function linkKeymasterAnalyticsAPI() {
  return fetchData(PUSH_EVENT_ANALYTICS, 'GET', fetchHeadersEmpty(), null, '');
}

function sendWorkAnalytics(ID, SOURCE, TYPE, REGISTERED)  {
  SOURCE = (SOURCE !== undefined && SOURCE !== null) ? SOURCE : "O";
  TYPE = (TYPE !== undefined && TYPE !== null) ? TYPE : "O";
  return fetchDataIgnoreErrors(setPathVariable(PUSH_WORK_ANALYTICS, 'id', ID, 'source', SOURCE, 'type', TYPE, 'registered', REGISTERED, ), 'GET', fetchHeadersEmpty(), null, '');
}

// #############################
// ###      Mock Data        ###
// #############################

function generateConfigMockData() {
  return CONFIG_DATA;
}

function generateGetServiceSymptomsMockData() {
  return [SYMPTOM, SYMPTOM];
}

function generateGetSlotsMockData() {
  return [SLOT, SLOT];
}

function generateTokenMockData() {
  return TOKEN;
}

function generateClientDetailsMockData() {
  return CLIENT_DETAILS;
}

function generateVihecleListMockData() {
  return [ITEM_DETAILS_WITH_SERVICES, ITEM_DETAILS_WITH_SERVICES, ITEM_DETAILS_WITH_SERVICES];
}

function generateFoListMockData() {
  return SERVICE_WITH_ITEM;
}

function generateFoDetailsMockData() {
  return SERVICE_DETAILS;
}

function generateItemInfoMockData() {
  return ITEM_DETAILS_WITHS_SERVICES_AND_SCRAPING;
}

// #############################
// ###    NOTIFICATIONS      ###
// #############################

function requestNotificationsList(TOKEN, BODY) {
  return fetchData(NOTIFICATIONS_LIST, 'GET', fetchHeadersWithAuth(TOKEN), BODY);
}

function requestNotificationsListChanges(TOKEN, BODY) {
  return fetchData(NOTIFICATIONS_LIST_CHANGES, 'POST', fetchHeadersWithAuth(TOKEN), BODY, []);
}

function readNotification(TOKEN, ID, BODY) {
  return fetchData(setPathVariable(NOTIFICATION_READ, 'id', ID), 'PUT', fetchHeadersWithAuth(TOKEN), BODY);
}

function readAllNotifications(TOKEN, BODY) {
  return fetchData(NOTIFICATION_READ_ALL, 'PUT', fetchHeadersWithAuth(TOKEN), BODY);
}

function deleteNotification(TOKEN, ID, BODY) {
  return fetchData(setPathVariable(NOTIFICATION_DELETE, 'id', ID), 'DELETE', fetchHeadersWithAuth(TOKEN), BODY);
}

export {
  fetchHeadersWithAuth, fetchHeaders,
  getAppConfig, getServiceSymptoms, getSlots, defineNewPasswordForClient, registerNewClient, sendNewEmailConfirmation, authenticateUser, authenticateProviderUser, authenticateDemoUser,
  recoverUserPassword, userSignUp, provideServiceFeedback, getClientData, updateClientData, deleteClientData, alterUserPassword, getItemList,
  addItemToUser, getFoListForClient, cancelFo, getFoDetails, scheduleService, getItemInfo, changeItemImage, preUserSignUp,
  confirmEmail, removeItemFromUser, alterItemDetails, requestSuport, submitApplicationFeedback, submitEstablishmentFeedback, getScheduleServices, getScheduleDetails,
  getInProgressServices, getCompletedServices, getAvailableEstablishments, getAllEstablishmentDistance, getEstablishmentDistance,
  getEstablishmentTerms, getItemScheduleServices, getItemInProgressServices, getItemCompletedServices, resetItemDetails, getCountriesListData,
  requestInvoice, getShowcaseWorkDetails, getShowcaseWorkDetailsCal, submitApproveApi, getClusterCookie, getCompaniesListData, getNewCompaniesListData,addClientServiceObs,
  addMediaFile, linkKeymasterAnalyticsAPI, removeMediaFile, getUploadConfig, getCompaniesCountData, readServiceObs, readServiceCheck, postLogLite, editPersonalServiceObs, initialPreSignup,
  requestPhoneCodeToValidate, validateUserPhone, requestEmailCodeToValidate, validateUserEmail, sendWorkAnalytics, getCompanyIAgreeData, requestNotificationsList, requestNotificationsListChanges, 
  readNotification, readAllNotifications, deleteNotification, postShowcaseCalConfirm
};
